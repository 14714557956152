import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { SessionKit } from '@wharfkit/session';
import { WebRenderer } from '@wharfkit/web-renderer';
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor';
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet';
import Cookies from 'js-cookie';
import config from '../config';
import endpoints from '../utils/endpoints'; // Importa endpoints dinâmicos
import logo from '../assets/logo.png';
import './Header.css';

const Header = () => {
  const { userAccount, login, logout, setRacingCoins, racingCoins } = useAuth();
  const [sessionKit, setSessionKit] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const ui = new WebRenderer();
    const anchor = new WalletPluginAnchor();
    const cloudWallet = new WalletPluginCloudWallet({
      supportedChains: [endpoints.CHAIN.id], // Chain ID dinâmico
      url: endpoints.CLOUD_WALLET.url, // URL dinâmica
      autoUrl: endpoints.CLOUD_WALLET.autoUrl, // Auto URL dinâmica
      loginTimeout: endpoints.CLOUD_WALLET.loginTimeout, // Timeout dinâmico
    });

    const kit = new SessionKit({
      appName: 'Pixel Races',
      chains: [
        {
          id: endpoints.CHAIN.id, // Chain ID dinâmico
          url: endpoints.CHAIN.url, // URL dinâmica da rede
        },
      ],
      ui,
      walletPlugins: [anchor, cloudWallet],
    });

    setSessionKit(kit);

    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/auth/csrf-token`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setCsrfToken(data.csrfToken);
          Cookies.set('csrfToken', data.csrfToken, { expires: 1 });
        }
      } catch {}
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = Cookies.get('authToken');
      if (token) {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const expiryTime = decodedToken.exp * 1000;
        const currentTime = Date.now();

        if (expiryTime - currentTime < 10 * 60 * 1000) {
          fetch(`${config.apiBaseUrl}/api/auth/renew-token`, {
            method: 'POST',
            headers: {
              'CSRF-Token': csrfToken || Cookies.get('csrfToken'),
            },
            credentials: 'include',
          })
            .then((response) => response.json())
            .then((data) => {
              Cookies.set('authToken', data.token, { expires: 1 });
            })
            .catch(() => {});
        }
      }
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [csrfToken]);

  const handleLogin = async () => {
    if (!sessionKit || (!csrfToken && !Cookies.get('csrfToken'))) {
      alert('CSRF Token is missing. Please refresh the page or try again later.');
      return;
    }

    try {
      const loginResult = await sessionKit.login();

      if (
        loginResult?.session?.permissionLevel?.actor &&
        typeof loginResult.session.permissionLevel.actor.toString === 'function'
      ) {
        const walletAddress = loginResult.session.permissionLevel.actor.toString();

        const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken || Cookies.get('csrfToken'),
          },
          body: JSON.stringify({ walletAddress }),
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          Cookies.set('authToken', data.token, { expires: 1 });
          setRacingCoins(data.racingCoins);
          checkAdmin(walletAddress, data.token);
        }
      }
    } catch {}
  };

  const checkAdmin = async (walletAddress, token) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/auth/check-admin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'CSRF-Token': csrfToken || Cookies.get('csrfToken'),
        },
        body: JSON.stringify({ walletAddress }),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        login(walletAddress, data.isAdmin, racingCoins);
      } else {
        login(walletAddress, false, racingCoins);
      }
    } catch {
      login(walletAddress, false, racingCoins);
    }
  };

  const handleLogout = async () => {
    logout();
    Cookies.remove('authToken');
    Cookies.remove('csrfToken');
    Cookies.remove('walletAddress');
    setCsrfToken('');

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/auth/csrf-token`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setCsrfToken(data.csrfToken);
        Cookies.set('csrfToken', data.csrfToken, { expires: 1 });
      }
    } catch {}
  };

  const handlePlayNow = () => {
    navigate('/homegame');
  };

  const renderFallingPixels = () => {
    const pixels = [];
    const totalPixels = 10;

    for (let i = 0; i < totalPixels; i++) {
      const style = {
        left: `${(i / totalPixels) * 110}%`,
        animationDuration: `${2 + Math.random() * 3}s`,
      };
      pixels.push(<div key={i} className="pixel" style={style}></div>);
    }
    return pixels;
  };

  return (
    <header className="menu">
      <div className="logo">
        <img src={logo} alt="Pixel Races Logo" className="logo-image" />
      </div>
      <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        &#9776;
      </button>
      <nav className={`nav-center ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <a onClick={() => navigate('/')}>Home</a>
          </li>
          <li>
            <a onClick={() => navigate('/drops')}>Drops</a>
          </li>
          <li>
            <a onClick={() => navigate('/stake')}>Stake</a>
          </li>
          <li>
            <a onClick={() => navigate('/about')}>About</a>
          </li>
          {userAccount && (
            <>
              <li>
                <button className="play-now-button" onClick={handlePlayNow}>
                  <i className="fas fa-gamepad"></i>
                  <span>Play</span>
                </button>
              </li>
              <li>
                <button className="logout-button" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </button>
              </li>
            </>
          )}
          {!userAccount && (
            <li>
              <button className="login-button" onClick={handleLogin}>
                <i className="fas fa-sign-in-alt"></i>
                <span>Login</span>
              </button>
            </li>
          )}
        </ul>
      </nav>
      {userAccount && (
        <div className="user-info-container">
          <p className="user-info">{userAccount.toString()}</p>
        </div>
      )}
      {renderFallingPixels()}
    </header>
  );
};

export default Header;
