import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './Layout.css';

const Layout = () => {
   return (
    <div className="layout-container">
      <Header />
      <div className="content-container">
        <Outlet />
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Layout;
