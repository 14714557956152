import React from 'react';
import './Footer.css';
import atomicHubIcon from '../assets/atomic-hub-icon.png'; // Certifique-se de que o caminho está correto
import xTwitterIcon from '../assets/x-twitter-icon.png'; // Certifique-se de que o caminho está correto
import discordIcon from '../assets/discord-icon.png'; // Certifique-se de que o caminho está correto

const Footer = () => {

  // Função para renderizar pixels caindo
  const renderFallingPixels = () => {
    const pixels = [];
    const totalPixels = 10; // Aumente o número de pixels
  
    for (let i = 0; i < totalPixels; i++) {
      const style = {
        left: `${(i / totalPixels) * 110}%`, // Distribuição uniforme ao longo do footer
        animationDuration: `${2 + Math.random() * 3}s`,
      };
      pixels.push(<div key={i} className="pixel" style={style}></div>);
    }
    return pixels;
  };

  return (
    <footer className="footer">
      <div className="footer-column-left">
        {/* Você pode adicionar conteúdo na coluna esquerda se precisar */}
      </div>
      <div className="footer-column-center">
        <p>&copy; 2024 Pixel Races. All rights reserved.</p>
      </div>
      <div className="footer-column-right">
        <div className="social-icons">
          <a href="https://x.com/pixelracesgo" target="_blank" rel="noopener noreferrer" className="x-twitter-icon">
            <img src={xTwitterIcon} alt="X (Twitter)" className="social-icon" />
          </a>
          <a href="https://discord.gg/AxcgqZqrWz" target="_blank" rel="noopener noreferrer" className="discord-icon">
          <img src={discordIcon} alt="X (Discord)" className="social-icon" />
          </a>
          <a href="https://atomichub.io/explorer/collection/wax-mainnet/pixelracesgo" target="_blank" rel="noopener noreferrer" className="atomic-hub-icon">
            <img src={atomicHubIcon} alt="AtomicHub" className="social-icon" />
          </a>
        </div>
      </div>
      {renderFallingPixels()} {/* Adiciona os pixels caindo ao footer */}
    </footer>
  );
};

export default Footer;
