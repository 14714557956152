import React, { useState, useEffect } from 'react';
import { updateStakeConfig, fetchStakeConfig } from '../../utils/api';
import Cookies from 'js-cookie';

const StakeConfigSettings = () => {
  const [stakeConfig, setStakeConfig] = useState({
    rewardValues: {
      ultimate: 10,
      exotic: 8,
      mythic: 6,
      epic: 4,
      legendary: 3,
      rare: 2,
      uncommon: 1,
      common: 0.5,
    },
    totalNftsInStake: {
      ultimate: 0,
      exotic: 0,
      mythic: 0,
      epic: 0,
      legendary: 0,
      rare: 0,
      uncommon: 0,
      common: 0,
    },
    saldo: 0,
    status: 'active',
    newBalance: 0,
  });

  useEffect(() => {
    const loadStakeConfig = async () => {
      const token = Cookies.get('authToken');
      const config = await fetchStakeConfig(token);

      // Convertendo Decimal128 para número
      const convertedConfig = {
        ...config,
        saldo: parseFloat(config.saldo.$numberDecimal),
        rewardValues: Object.fromEntries(
          Object.entries(config.rewardValues).map(([key, value]) => [key, parseFloat(value.$numberDecimal)])
        ),
      };

      setStakeConfig(convertedConfig);
    };
    loadStakeConfig();
  }, []);

  const handleStakeConfigChange = (event, category, field) => {
    const value = event.target.value.replace(',', '.'); // Substituindo vírgula por ponto
    setStakeConfig((prevConfig) => ({
      ...prevConfig,
      [category]: { ...prevConfig[category], [field]: value },
    }));
  };

  const handleUpdateStakeConfig = async () => {
    const token = Cookies.get('authToken');

    // Converter strings para números decimais antes de enviar para o backend
    const rewardValuesParsed = {};
    Object.keys(stakeConfig.rewardValues).forEach((key) => {
      rewardValuesParsed[key] = parseFloat(stakeConfig.rewardValues[key]?.toString().replace(',', '.') || 0);
    });

    const cleanStakeConfig = {
      rewardValues: rewardValuesParsed,
      status: stakeConfig.status,
      totalNftsInStake: stakeConfig.totalNftsInStake,
    };

    try {
      await updateStakeConfig(cleanStakeConfig, token);
      alert('Stake configuration updated successfully!');
    } catch (error) {
      console.error('Failed to update stake configuration:', error);
    }
  };

  const handleDepositSaldo = async () => {
    const token = Cookies.get('authToken');
    const newBalance = parseFloat(stakeConfig.newBalance.toString().replace(',', '.'));

    if (isNaN(newBalance)) {
      alert('Por favor, insira um valor válido para o saldo.');
      return;
    }

    // Atualizando apenas o saldo
    const updatedConfig = {
      saldo: (parseFloat(stakeConfig.saldo) + newBalance).toString(),
    };

    try {
      await updateStakeConfig(updatedConfig, token);
      alert('Saldo depositado com sucesso!');

      // Atualizar o saldo no estado local
      setStakeConfig((prevConfig) => ({
        ...prevConfig,
        saldo: parseFloat(prevConfig.saldo) + newBalance,
        newBalance: 0, // Reseta o campo de input
      }));
    } catch (error) {
      console.error('Failed to deposit saldo:', error);
    }
  };

  return (
    <div>
      <h3>Stake Configuration</h3>
      <h4>Reward Values</h4>
      {Object.keys(stakeConfig.rewardValues).map((rarity) => (
        <div key={rarity}>
          <label>
            {rarity}:
            <input
              type="text"
              inputMode="decimal"
              pattern="^\d*\.?\d*$"
              value={stakeConfig.rewardValues[rarity]}
              onChange={(e) => handleStakeConfigChange(e, 'rewardValues', rarity)}
            />
          </label>
        </div>
      ))}

      <button onClick={handleUpdateStakeConfig}>Update Stake Configuration</button>

      <h4>Total NFTs in Stake</h4>
      {Object.keys(stakeConfig.totalNftsInStake).map((rarity) => (
        <div key={rarity}>
          <label>
            {rarity}:
            <input
              type="number"
              value={stakeConfig.totalNftsInStake[rarity]}
              disabled
            />
          </label>
        </div>
      ))}

      <h4>Stake Balance</h4>
      <div>
        <label>Saldo Atual: {parseFloat(stakeConfig.saldo).toFixed(2)}</label>
      </div>
      <div>
        <label>Depositar Saldo:</label>
        <input
          type="text"
          inputMode="decimal"
          pattern="^\d*\.?\d*$"
          value={stakeConfig.newBalance}
          onChange={(e) =>
            setStakeConfig({
              ...stakeConfig,
              newBalance: e.target.value.replace(',', '.'), // Substituindo vírgula por ponto
            })
          }
        />
        <button onClick={handleDepositSaldo}>Depositar</button>
      </div>

      <div>
  <label>Status Atual: {stakeConfig.status === 'active' ? 'Ativo' : 'Inativo'}</label>
  <button
    onClick={() =>
      setStakeConfig({
        ...stakeConfig,
        status: stakeConfig.status === 'active' ? 'inactive' : 'active',
      })
    }
  >
    {stakeConfig.status === 'active' ? 'Desativar' : 'Ativar'} Stake
  </button>
</div>
    </div>
  );
};

export default StakeConfigSettings;
