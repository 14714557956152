import React, { useState, useEffect } from 'react';
import { updateGlobalValues, fetchGlobalValues } from '../../utils/api';
import Cookies from 'js-cookie';

const GlobalSettings = () => {
  const [globalValues, setGlobalValues] = useState({
    raceLimit: 0,
    limitInterval: 0,
    verificationInterval: 30,
    batchSize: 100,
    maxRetries: 3,
    rateLimitDelay: 1000,
    rewardDistributionInterval: 3600
  });

  useEffect(() => {
    const fetchValues = async () => {
      const token = Cookies.get('authToken');
      const values = await fetchGlobalValues(token);
      setGlobalValues(values);
    };
    fetchValues();
  }, []);

  const handleGlobalValuesChange = (event) => {
    const { name, value } = event.target;
    setGlobalValues({ ...globalValues, [name]: parseInt(value, 10) });
  };

  const handleUpdateGlobalValues = async () => {
    const token = Cookies.get('authToken');
    await updateGlobalValues(globalValues, token);
  };

  return (
    <div>
      <h3>Global Settings</h3>
      <label>Race Limit: 
        <input type="number" name="raceLimit" value={globalValues.raceLimit} onChange={handleGlobalValuesChange} />
      </label>
      <label>Limit Interval (seconds):
        <input type="number" name="limitInterval" value={globalValues.limitInterval} onChange={handleGlobalValuesChange} />
      </label>
      <button onClick={handleUpdateGlobalValues}>Update Global Values</button>
    </div>
  );
};

export default GlobalSettings;
