import React, { useState, useEffect } from 'react';
import { toggleEosjsVerification, toggleBackendVerification, fetchVerificationSettings, updateGlobalValues } from '../../utils/api';
import Cookies from 'js-cookie';

const NftVerificationSettings = () => {
    const [useEosjsVerification, setUseEosjsVerification] = useState(false);
    const [useBackendVerification, setUseBackendVerification] = useState(true);
    const [verificationSettings, setVerificationSettings] = useState({
      verificationInterval: 30,
      batchSize: 50,
      maxRetries: 3,
      rateLimitDelay: 1000,
      rewardDistributionInterval: 3600
    });
  
    useEffect(() => {
      const loadVerificationSettings = async () => {
        const token = Cookies.get('authToken');
        const settings = await fetchVerificationSettings(token); // Obtém os valores de verificação da API
        setUseEosjsVerification(settings.useEosjs);
        setUseBackendVerification(settings.useBackend);
        setVerificationSettings({
          verificationInterval: settings.verificationInterval || 30,
          batchSize: settings.batchSize || 50,
          maxRetries: settings.maxRetries || 3,
          rateLimitDelay: settings.rateLimitDelay || 1000,
          rewardDistributionInterval: settings.rewardDistributionInterval || 3600
        });
      };
      loadVerificationSettings();
    }, []);
  
    // Função para atualizar APENAS as configurações de verificação de NFTs
    const handleUpdateVerificationSettings = async () => {
      const token = Cookies.get('authToken');
      await updateGlobalValues(
        { 
          verificationInterval: verificationSettings.verificationInterval,
          batchSize: verificationSettings.batchSize,
          maxRetries: verificationSettings.maxRetries,
          rateLimitDelay: verificationSettings.rateLimitDelay,
          rewardDistributionInterval: verificationSettings.rewardDistributionInterval 
        }, 
        token
      );
    };
  
    return (
      <div>
        <h3>NFT Verification Settings</h3>
        <label>
          <input
            type="checkbox"
            checked={useEosjsVerification}
            onChange={async () => {
              const token = Cookies.get('authToken');
              const result = await toggleEosjsVerification(!useEosjsVerification, token);
              setUseEosjsVerification(result.useEosjsVerification);
            }}
          />
          Enable Eosjs Verification
        </label>
        <label>
          <input
            type="checkbox"
            checked={useBackendVerification}
            onChange={async () => {
              const token = Cookies.get('authToken');
              const result = await toggleBackendVerification(!useBackendVerification, token);
              setUseBackendVerification(result.useBackend);
            }}
          />
          Enable Backend Verification
        </label>
        <div>
          <label>Verification Interval (seconds):
            <input
              type="number"
              name="verificationInterval"
              value={verificationSettings.verificationInterval}
              onChange={(e) => setVerificationSettings({ ...verificationSettings, verificationInterval: e.target.value })}
            />
          </label>
        </div>
        <div>
          <label>Batch Size:
            <input
              type="number"
              name="batchSize"
              value={verificationSettings.batchSize}
              onChange={(e) => setVerificationSettings({ ...verificationSettings, batchSize: e.target.value })}
            />
          </label>
        </div>
        <div>
          <label>Max Retries:
            <input
              type="number"
              name="maxRetries"
              value={verificationSettings.maxRetries}
              onChange={(e) => setVerificationSettings({ ...verificationSettings, maxRetries: e.target.value })}
            />
          </label>
        </div>
        <div>
          <label>Rate Limit Delay (ms):
            <input
              type="number"
              name="rateLimitDelay"
              value={verificationSettings.rateLimitDelay}
              onChange={(e) => setVerificationSettings({ ...verificationSettings, rateLimitDelay: e.target.value })}
            />
          </label>
        </div>
        <div>
          <label>Reward Distribution Interval (seconds):
            <input
              type="number"
              name="rewardDistributionInterval"
              value={verificationSettings.rewardDistributionInterval}
              onChange={(e) => setVerificationSettings({ ...verificationSettings, rewardDistributionInterval: e.target.value })}
            />
          </label>
        </div>
        <button onClick={handleUpdateVerificationSettings}>Update Verification Settings</button>
      </div>
    );
  };
  
  export default NftVerificationSettings;