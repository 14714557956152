// src/utils/api.js

import Cookies from 'js-cookie';
import config from '../config';
import ENDPOINTS from '../utils/endpoints';

const API_BASE_URL = `${config.apiBaseUrl}/api`;

// URLs específicas para a testnet do WAX
const WAX_API_ENDPOINTS = ENDPOINTS.WAX_API;

const getAuthToken = () => {
  const token = Cookies.get('authToken');
  return token;
};

const getCsrfToken = () => {
  const token = Cookies.get('csrfToken');
  return token;
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));  // Função de atraso

export const fetchGlobalValues = async () => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/protected/globalvalues`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch global values');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateGlobalValues = async (globalValues, token, csrfToken) => {
  return fetch(`${config.apiBaseUrl}/api/protected/globalvalues`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'CSRF-Token': csrfToken,
    },
    body: JSON.stringify(globalValues),
    credentials: 'include',
  });
};

export const fetchMaps = async () => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/protected/mapsadded`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch maps');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchRacingCoins = async (userAccount) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ walletAddress: userAccount }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch RacingCoins');
    }

    const data = await response.json();
    return data.user.racingCoins || 0;
  } catch (error) {
    throw error;
  }
};

export const updateRacingCoins = async (userAccount, additionalRacingCoins) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/auth/racingcoins/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ walletAddress: userAccount, racingCoins: additionalRacingCoins }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to update RacingCoins');
    }

    const data = await response.json();
    return data.racingCoins;
  } catch (error) {
    throw error;
  }
};


const fetchWithRetry = async (url, options = {}, retries = 3, backoff = 3000) => {
  for (let i = 0; i < retries; i++) {
      try {
          const response = await fetch(url, options);
          if (!response.ok) {
              if (response.status === 429 && i < retries - 1) {  // Se receber erro 429
                  await delay(backoff);  // Atraso exponencial
                  backoff *= 2;  // Aumenta o backoff exponencialmente
                  continue;  // Tente novamente
              } else {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }
          }
          return await response.json();
      } catch (error) {
          if (i === retries - 1) throw error;  // Se for a última tentativa, lance o erro
      }
  }
};

export const fetchNFTs = async (userAccount) => {
  let allNfts = [];
  let page = 1;
  const limit = 50;  // Limite de NFTs por página
  let hasMore = true;

  try {
      while (hasMore) {
         const url = `${WAX_API_ENDPOINTS.FETCH_NFTS}?collection_name=${ENDPOINTS.COLLECTION_NAME}&schema_name=cars&owner=${userAccount}&page=${page}&limit=${limit}`;
          const data = await fetchWithRetry(url);

          if (data && data.data && data.data.length > 0) {
              allNfts = [...allNfts, ...data.data];
              page += 1;

              // Adicionando um pequeno atraso entre as requisições
              await delay(500);  
          } else {
              hasMore = false;
          }
      }
      return allNfts;
  } catch (error) {
      throw error;
  }
};

export const fetchOngoingRaces = async (userAccount) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/races/ongoing?walletAddress=${userAccount}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch ongoing races');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveReward = async (reward) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/rewards/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(reward),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to save reward');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRewards = async (userAccount) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/rewards/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ userAccount }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch rewards');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const collectRewards = async (userAccount) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();

    const response = await fetch(`${API_BASE_URL}/rewards/collect`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ userAccount }),
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json(); // Capturar JSON de erro do backend
      throw errorData; // Lançar os detalhes do erro para o frontend lidar
    }

    const data = await response.json();

    // Retorna os dados completos para serem usados na RewardsModal
    return data;
  } catch (error) {
    if (error.details) {
      // Capturar e propagar detalhes se presentes
      throw { message: 'Failed to collect rewards', details: error.details };
    } else {
      throw error;
    }
  }
};


export const fetchUser = async (walletAddress) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/users/${walletAddress}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkCarRaceStatus = async (carId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/races/ongoing?carId=${carId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to check car race status');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkCarInRace = async (carId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/checkCarInRace/${carId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Erro na verificação do status da corrida: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return { inRace: false };
  }
};

export const deleteMap = async (mapId) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/mapsadded/${mapId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to delete map');
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting map:', error);
    throw error;
  }
};

// Nova função para buscar o ranking
export const fetchRanking = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/ranking`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch ranking');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch ranking:', error);
    throw error;
  }
};


export const createSeason = async (seasonData) => {
  const token = getAuthToken();  // Retrieve auth token
  const csrfToken = getCsrfToken();  // Retrieve CSRF token

  const response = await fetch(`${API_BASE_URL}/seasons`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,  // Include auth token
      'CSRF-Token': csrfToken,  // Include CSRF token
    },
    body: JSON.stringify(seasonData),
    credentials: 'include',  // Include cookies
  });

  if (!response.ok) {
    const errorText = await response.text(); // Get error text for debugging
    console.error('Failed to create season:', errorText);
    throw new Error('Failed to create season');
  }

  return response.json();
};

export const fetchSeasons = async () => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch seasons');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateSeason = async (seasonId, updates) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons/${seasonId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(updates),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to update season');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteSeason = async (seasonId) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons/${seasonId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to delete season');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchActiveSeason = async () => {
  const response = await fetch(`${API_BASE_URL}/seasons/active`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch active season');
  }

  return response.json();
};

// Função para verificar quais NFTs estão em stake
export const checkStake = async () => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();

      const response = await fetch(`${API_BASE_URL}/stake/check`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Failed to check staked NFTs');
      }

      const data = await response.json();
      return data; // Certifique-se de que o retorno está correto
  } catch (error) {
      console.error('Erro na função checkStake:', error); // Mantido para registrar erros
      throw error;
  }
};

// Função para enviar NFTs para stake
export const createStake = async (nftIds, walletAddress) => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/stake`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ nfts: nftIds, walletAddress }), // Adiciona 'walletAddress' ao corpo da requisição
          credentials: 'include'
      });

      if (!response.ok) {
          const errorData = await response.json().catch(() => {
              // Caso a resposta não seja JSON
              return { message: 'Erro desconhecido no servidor', details: response.statusText };
          });
          throw new Error(errorData.message || 'Failed to stake NFTs');
      }

      const data = await response.json();
      return data;
  } catch (error) {
      throw error;
  }
};

export const getStakeCoins = async () => {
  try {
      const token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/stake/coins`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': getCsrfToken()
          },
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Failed to fetch stake coins');
      }

      const data = await response.json();
      return { stakeCoins: data.stakeCoins, lastCollected: data.lastCollected };
  } catch (error) {
      console.error("Error fetching stake coins:", error); // Mantido para registrar erros
      throw error;
  }
};

// Manipular dados do stakeconfigs da db
export const fetchStakeConfig = async () => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/stakeconfig`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          credentials: 'include'
      });

      if (!response.ok) throw new Error('Failed to fetch stake config');

      return await response.json();
  } catch (error) {
      throw error;
  }
};

// Função para atualizar a configuração de stake
export const updateStakeConfig = async (configData) => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/stakeconfig`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify(configData),
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Failed to update stake config');
      }

      return await response.json();
  } catch (error) {
      console.error('Erro ao atualizar configuração de stake:', error); // Mantido para registrar erros
      throw error;
  }
};


// Função para buscar as configurações de verificação de posse de NFTs
export const fetchVerificationSettings = async () => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/globalvalues/verification-settings`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch verification settings');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// Função para alternar o uso do Eosjs
export const toggleEosjsVerification = async (enabled) => {
  const token = getAuthToken();
  const csrfToken = getCsrfToken();
  const response = await fetch(`${config.apiBaseUrl}/api/globalvalues/eosjs-toggle`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ enabled }),
      credentials: 'include'
  });

  if (!response.ok) throw new Error('Failed to toggle Eosjs verification');

  return await response.json();
};

// Função para alternar o uso do backend para verificação
export const toggleBackendVerification = async (enabled) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/globalvalues/backend-toggle`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ enabled }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to toggle backend verification');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};


// Função para remover NFTs do stake
export const removeStake = async (nftIds) => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/stake/remove`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ nfts: nftIds }),
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Failed to remove NFTs from stake');
      }

      return await response.json();
  } catch (error) {
      console.error("Erro ao remover NFTs do stake:", error); // Mantido para registrar erros
      throw error;
  }
};

// Função para buscar detalhes do NFT pelo ID
export const fetchNFTDetails = async (nftId) => {
  try {
      const url = `${WAX_API_ENDPOINTS.FETCH_NFT_DETAILS}/${nftId}`;
      const response = await fetch(url);
      const data = await response.json();
      return data.data;
  } catch (error) {
      throw error;
  }
};

// Função para depositar saldo no stake
export const depositStake = async (amount) => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${config.apiBaseUrl}/stakeconfig/deposit`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ newBalance: amount }),  // Certifique-se de que "newBalance" é enviado
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Failed to update stake config');
      }

      return await response.json();
  } catch (error) {
      console.error('Erro ao depositar saldo no stake:', error); // Mantido para registrar erros
      throw error;
  }
};

// Nova função para buscar saldo de PXRS
export const fetchPXRSBalance = async (userAccount) => {
  try {
    const response = await fetch(ENDPOINTS.WAX_API.PXRS_BALANCE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: ENDPOINTS.PXRS_CONTRACT, // Código do contrato dinâmico
        account: userAccount,
        symbol: 'PXRS', // Caso precise ser dinâmico, mova para os endpoints.js
      }),
    });

    if (response.ok) {
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        const balanceString = data[0];
        const [amount] = balanceString.split(' ');
        const balance = parseFloat(amount);
        return balance;
      }

      return 0;
    }

    return 0;
  } catch (error) {
    throw error;
  }
};


export const fetchItems = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/items`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to fetch items');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const createItem = async (itemData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/items`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify(itemData), // itemData agora inclui isCollection
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to create item');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteItem = async (itemId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/items/${itemId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to delete item');
    }
  } catch (error) {
    throw error;
  }
};

export const fetchFilteredNFTs = async (userAccount) => {
  try {
    const response = await fetch(`${API_BASE_URL}/filtered-nfts?userAccount=${userAccount}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch filtered NFTs');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};


export const collectStakeCoins = async (userAccount) => {
  const token = getAuthToken();
  const csrfToken = getCsrfToken();

  try {
      const response = await fetch(`${API_BASE_URL}/stake/collect`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ userAccount }),
          credentials: 'include'
      });

      if (response.status === 400) {
          const data = await response.json();
          return { error: true, message: data.message };
      } else if (!response.ok) {
          return { error: true, message: 'Unexpected error occurred' };
      }

      const data = await response.json();
      return { error: false, totalCoins: data.totalCoins };
  } catch (error) {
      return { error: true, message: 'Network or unexpected error' };
  }
};


export const checkItemInRace = async (itemId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/checkItemInRace/${itemId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to check item race status');
    }

    const data = await response.json();
    return data.inRace; // Ensure this is returning the correct boolean value
  } catch (error) {
    console.error('Error checking item race status:', error); // Mantido para registrar erros
    return false; // Return false if there's an error
  }
};


// Exemplo de como criar um mapa (createMap)
export const createMap = async (mapData) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${config.apiBaseUrl}/api/mapsadded`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken,
      },
      body: JSON.stringify(mapData),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to create map');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating map:', error); // Mantido para registrar erros
    throw error;
  }
};

export const updateItem = async (itemId, itemData) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/items/${itemId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(itemData), // itemData agora inclui isCollection
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to update item');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

// Função para resetar rankings de todos os usuários
export const resetUserRankings = async (token) => {
  const csrfToken = getCsrfToken();
  const response = await fetch(`${API_BASE_URL}/reset-rankings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'CSRF-Token': csrfToken
    },
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error('Failed to reset rankings');
  }

  return await response.json();
};


export const fetchPublicItems = async () => {
  const response = await fetch(`${API_BASE_URL}/public/items`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch public items');
  }

  const data = await response.json();
  return data;
};

// Add function to distribute rewards
export const distributeSeasonRewardsManually = async (seasonId) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons/distribute-rewards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ seasonId }), // Ensure seasonId is included here
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to distribute rewards:', errorText); // Mantido para registrar erros
      throw new Error('Failed to distribute rewards');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

// Add function to update season status
export const updateSeasonStatus = async (seasonId, status) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons/update-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ seasonId, status }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to update season status');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

// ROTAS DE BUILD

export const saveBuildToDB = async (userAccount, buildName, cars, items) => {
  const token = Cookies.get('authToken');
  const csrfToken = Cookies.get('csrfToken');

  const response = await fetch(`${API_BASE_URL}/builds/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'CSRF-Token': csrfToken
    },
    body: JSON.stringify({ userAccount, buildName, cars, items }),
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.error || 'Failed to save build');
  }

  return await response.json();
};

// Função para obter todas as builds do usuário
export const getBuildsFromDB = async (userAccount) => {
  const token = Cookies.get('authToken');
  const csrfToken = Cookies.get('csrfToken');

  const response = await fetch(`${API_BASE_URL}/builds?userAccount=${userAccount}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'CSRF-Token': csrfToken
    },
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error('Failed to fetch builds');
  }

  return await response.json();
};

// Função para carregar uma build específica
export const loadBuildFromDB = async (userAccount, buildName) => {
  const token = Cookies.get('authToken');
  const csrfToken = Cookies.get('csrfToken');

  const response = await fetch(`${API_BASE_URL}/builds/load?userAccount=${userAccount}&buildName=${encodeURIComponent(buildName)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'CSRF-Token': csrfToken
    },
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.error || 'Failed to load build');
  }

  return await response.json();
};

// Função para deletar uma build específica
export const deleteBuildFromDB = async (userAccount, buildName) => {
  const token = Cookies.get('authToken');
  const csrfToken = Cookies.get('csrfToken');

  const response = await fetch(`${API_BASE_URL}/builds/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'CSRF-Token': csrfToken
    },
    body: JSON.stringify({ userAccount, buildName }),
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.error || 'Failed to delete build');
  }

  return await response.json();
};


// PROCURAR RANKING INDIVIDUAL DO USUARIO BASEADO NO XP DELE PARA O RANKING DO HEADERGAME
export const fetchUserRank = async (walletAddress) => {
  try {
    const response = await fetch(`${API_BASE_URL}/ranking/${walletAddress}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user rank');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch user rank:', error); // Mantido para registrar erros
    throw error;
  }
};
