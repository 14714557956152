import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [userAccount, setUserAccount] = useState(Cookies.get('userAccount') || null);
    const [isAdmin, setIsAdmin] = useState(Cookies.get('isAdmin') === 'true');
    const [racingCoins, setRacingCoins] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const account = Cookies.get('userAccount');
        const adminStatus = Cookies.get('isAdmin') === 'true';
        setUserAccount(account);
        setIsAdmin(adminStatus);
        
        if (account) {
            fetchUserAccount(account).then(data => {
                setRacingCoins(data.racingCoins);
                setLoading(false);
            }).catch(err => {
                console.error('Error fetching user account:', err);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    const login = (account, adminStatus, coins) => {
        setUserAccount(account);
        setIsAdmin(adminStatus);
        setRacingCoins(coins);
        Cookies.set('userAccount', account, { expires: 1 });
        Cookies.set('isAdmin', adminStatus.toString(), { expires: 1 });
    };

    const logout = () => {
        setUserAccount(null);
        setIsAdmin(false);
        setRacingCoins(0);
        Cookies.remove('userAccount');
        Cookies.remove('isAdmin');
        Cookies.remove('authToken');
        Cookies.remove('csrfToken');
    };

    const updateRacingCoins = (coins) => {
        setRacingCoins(coins);
    };

    return (
        <AuthContext.Provider value={{ 
            userAccount, 
            isAdmin, 
            racingCoins, 
            login, 
            logout, 
            setUserAccount, // Adicionado ao contexto para ser usado em Header.js
            setRacingCoins: updateRacingCoins, 
            loading 
        }}>
            {children}
        </AuthContext.Provider>
    );
};

const fetchUserAccount = async (account) => {
    // Implementação fictícia para buscar dados do usuário
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ racingCoins: 100 }); // Exemplo de resposta
        }, 1000);
    });
};
