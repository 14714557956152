import React, { useState } from 'react';
import './About.css';
import { FaCar, FaMap, FaTrophy, FaBriefcase, FaCoins, FaInfoCircle, FaShieldAlt, FaChartPie, FaRoad } from 'react-icons/fa';
import trophyIcon from '../assets/seasontrophie.png';

const About = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="about-page">
      <h1>About Pixel Races</h1>

      <div className="about-section">
        <h2 onClick={() => toggleSection('howItWorks')}>
          <FaInfoCircle className="section-icon" /> How the Game Works
        </h2>
        {openSection === 'howItWorks' && (
          <div className="section-content">
            <p>
              In Pixel Races, you need a car to race, which can be purchased from our store on Neftyblocks. Once you have a car, you are ready to race.
              The cars have attributes like Car Rarity, Skin Rarity, Speed Level, Acceleration Level, Nitro Level, and Luck Level.
              Higher rarities and levels increase your chances of winning races and dropping NFTs.

              Each player can race up to 10 times every 24 hours. Once the 24-hour period has passed, your race limit will be reset, and you'll be able to race another 10 times.
            </p>
          </div>
        )}
      </div>

      {/* Tokenomics Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('tokenomics')}>
          <FaChartPie className="section-icon" /> Tokenomics
        </h2>
        {openSection === 'tokenomics' && (
          <div className="section-content">
            <p><strong>Max Supply:</strong> 300.000.000</p>
            <p><strong>Token Name:</strong> PXRS</p>
            <p><strong>Decimals:</strong> 8</p>
          </div>
        )}
      </div>

      {/* Future Use of Tokens Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('futureUse')}>
          <FaCoins className="section-icon" /> Future Utility of Tokens
        </h2>
        {openSection === 'futureUse' && (
          <div className="section-content">
            <p>
              PXRS tokens will be used for various features in the future, including:
            </p>
            <ul>
              <li>Tuning and upgrading cars for better performance.</li>
              <li>Purchasing exclusive NFTs.</li>
              <li>Accessing maps with exclusive NFTs.</li>
              <li>Buying items to boost racing capabilities.</li>
              <li>Tokens will be used for car repairs when they need maintenance.</li>
            </ul>
          </div>
        )}
      </div>

      {/* Cars Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('cars')}>
          <FaCar className="section-icon" /> Cars
        </h2>
        {openSection === 'cars' && (
          <div className="section-content">
            <p>To participate in races, you need a car from our store on Neftyblocks. Each car has attributes that affect performance:</p>
            <ul>
              <li><strong>Car Rarity:</strong> Common to Ultimate</li>
              <li><strong>Skin Rarity:</strong> Common to Ultimate</li>
              <li><strong>Speed Level:</strong> 1 to 10</li>
              <li><strong>Nitro Level:</strong> 1 to 10</li>
              <li><strong>Luck Level:</strong> 1 to 10</li>
              <li><strong>Acceleration:</strong> A performance intensity factor that determines the car's explosive start in races</li>
            </ul>
            <p>
              Car Rarity, Speed Level, and Nitro Level boost your chances of winning races. 
              Acceleration affects how quickly your car accelerates at the start, while Skin Rarity and Luck increase your chances of dropping NFTs. 
              Select a car to see its attributes and start racing!
            </p>
          </div>
        )}
      </div>

      {/* Items Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('items')}>
          <FaBriefcase className="section-icon" /> Items
        </h2>
        {openSection === 'items' && (
          <div className="section-content">
            <p>Boost your chances in races by using items from our store or partner collections. Items offer:</p>
            <ul>
              <li><strong>Win Boost:</strong> Higher chances to win or rank higher</li>
              <li><strong>Drop Boost:</strong> Increased NFT drop chances</li>
              <li><strong>EXP Boost:</strong> Gain more EXP to level up faster</li>
            </ul>
            <p>Items have rarities indicating their effectiveness and can give you an edge in races.</p>
          </div>
        )}
      </div>

      {/* Maps Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('maps')}>
          <FaMap className="section-icon" /> Maps
        </h2>
        {openSection === 'maps' && (
          <div className="section-content">
            <p>
              Maps are open to all cars, offering unique challenges with varying race times, number of competitors, and rewards.
              Each map has specific characteristics such as race time, number of racers, total coins, and potential NFTs to be dropped.
            </p>
          </div>
        )}
      </div>

      {/* Ranking Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('ranking')}>
          <FaTrophy className="section-icon" /> Ranking
        </h2>
        {openSection === 'ranking' && (
          <div className="section-content">
            <p>
              Compete to climb the leaderboard during active seasons by earning EXP and improving your level.
              Each race gives you EXP, and with more EXP, you level up and get ahead of your competitors.
            </p>
            <p>
              The ranking window displays the top racers of the season. Your wins, 2nd, and 3rd place finishes are tracked and displayed,
              along with season rewards like PXRS and NFTs.
            </p>
            <p>Note: Level, EXP, and rank are only updated or gained when a season is active. If there is no active season, any races you complete with items that boost EXP will not affect your level or rank.</p>
          </div>
        )}
      </div>

      {/* Ongoing Races & Rewards Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('ongoingRacesRewards')}>
          <FaBriefcase className="section-icon" /> Ongoing Races & Rewards
        </h2>
        {openSection === 'ongoingRacesRewards' && (
          <div className="section-content">
            <p>
              Track your active races and collect rewards once they finish. Earn PXRS coins and NFTs.
              Use the 'Collect All' button to gather all your rewards at once.
            </p>
            <p>
              Note: If you accumulate many rewards, collecting them might take a few extra seconds as the system mints your NFTs and transfers PXRS to your account.
            </p>
          </div>
        )}
      </div>

      {/* Race Rewards Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('raceRewards')}>
          <FaCoins className="section-icon" /> Race Rewards
        </h2>
        {openSection === 'raceRewards' && (
          <div className="section-content">
            <p>
              In Pixel Races, the total PXRS tokens distributed among players in a race is based on the specific map settings. Each map can distribute between <strong>5% and 100%</strong> of its total token value in every race.
            </p>
            <p> 
              <strong>Token Distribution:</strong>
            </p>
            <ul>
              <li>1st place: 30% of the distributed tokens.</li>
              <li>2nd place: 20% of the distributed tokens.</li>
              <li>3rd place: 10% of the distributed tokens.</li>
              <li>The remaining tokens are shared among the other racers, with the exact value determined by their positions.</li>
            </ul>
            <p>
              Example: If a map has 100,000 PXRS allocated, and 50% (50,000 PXRS) is set to be distributed, the top racer will receive 15,000 PXRS (30% of 50,000), the second will receive 10,000 PXRS, and the third will receive 5,000 PXRS.
            </p>
            <p>
              <strong>Car Rarity Influence:</strong> Car rarity significantly affects both the chance of winning races and the amount of PXRS earned. The following multipliers are applied based on the car's rarity:
            </p>
            <ul>
              <li>Common: 1.0x</li>
              <li>Uncommon: 1.1x</li>
              <li>Rare: 1.2x</li>
              <li>Legendary: 1.3x</li>
              <li>Epic: 1.4x</li>
              <li>Mythic: 1.5x</li>
              <li>Exotic: 1.6x</li>
              <li>Ultimate: 1.7x</li>
            </ul>
            <p>Example: A car with "Epic" rarity will have a 1.4x multiplier applied to its race performance and PXRS rewards.</p>
            
            <p>
              <strong>NFT Drops:</strong> Besides earning PXRS tokens, players also have a chance to earn NFT drops during races. These drops are influenced by two factors:
            </p>
            <ul>
              <li><strong>Luck Level:</strong> The higher the car’s Luck Level, the better the chance of earning an NFT drop.</li>
              <li><strong>Skin Rarity:</strong> The rarity of the car's skin also affects the chances, using the same multipliers as above.</li>
            </ul>
            <p>
              Example: A car with a Luck Level of 7 and a "Mythic" skin rarity (1.5x multiplier) will have a higher chance of dropping NFTs compared to a car with lower Luck Level or skin rarity.
            </p>
            <p>
              Each map contains a set of NFTs that can potentially drop during a race. The base chance of an NFT drop is calculated by combining the car's Luck Level and the Skin Rarity multiplier.
            </p>
          </div>
        )}
      </div>

      {/* Stake Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('stake')}>
          <FaCoins className="section-icon" /> Stake
        </h2>
        {openSection === 'stake' && (
          <div className="section-content">
            <p>Stake your NFTs to earn PXRS coins passively over time. PXRS stake coins can be withdrawn every 12 hours.</p>
          </div>
        )}
      </div>

      {/* Anti-Cheating System Section */}
      <div className="about-section">
        <h2 onClick={() => toggleSection('antiCheating')}>
          <FaShieldAlt className="section-icon" /> Anti-Cheating System
        </h2>
        {openSection === 'antiCheating' && (
          <div className="section-content">
            <p>
              Our anti-cheating system ensures fair play by preventing the same NFT from being used in multiple races or staking simultaneously.
              If you start a race with a specific NFT, it cannot be used in another race until the current one ends.
            </p>
            <p>
              Additionally, if you transfer your car NFT while it is in an ongoing race, the rewards will be given to the new owner if they have logged into the game at least once.
              If the new owner has never logged into the game, the reward will be deleted.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
