import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { fetchFilteredNFTs, checkItemInRace } from '../utils/api';
import '../styles/itemSelection.css';

const ItemSelection = ({
  setShowNFTSelector,
  handleEquipMultipleNFTs,
  maxItems = 3,
  allEquippedItemsGlobal,
  currentCarIndex,
  currentItemIndex,
  setNotificationMessage,
  setShowNotification
}) => {
  const { userAccount } = useAuth();
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [refreshCount, setRefreshCount] = useState(0);
  const [isCooldown, setIsCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [selectedRarities, setSelectedRarities] = useState([]);
  const [sortAttribute, setSortAttribute] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const MAX_REFRESH_CLICKS = 5;

  const getChanceForItem = (item) => {
    let victoryChance = 0;
    let dropChance = 0;
    let xpBonus = 0;

    if (item.schema?.schema_name === 'items' && item.data) {
      victoryChance = parseInt(item.data.win, 10) || 0;
      dropChance = parseInt(item.data.drop, 10) || 0;
      xpBonus = parseInt(item.data.exp, 10) || 0;
    }

    return { victoryChance, dropChance, xpBonus };
  };

  useEffect(() => {
    const savedSelectedRarities = JSON.parse(localStorage.getItem('selectedRarities_itemSelection')) || [];
    const savedSortAttribute = localStorage.getItem('sortAttribute_itemSelection') || '';
    const savedSortOrder = localStorage.getItem('sortOrder_itemSelection') || 'asc';
    const savedSelectedCollection = localStorage.getItem('selectedCollection_itemSelection') || '';

    setSelectedRarities(savedSelectedRarities);
    setSortAttribute(savedSortAttribute);
    setSortOrder(savedSortOrder);
    setSelectedCollection(savedSelectedCollection);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedRarities_itemSelection', JSON.stringify(selectedRarities));
  }, [selectedRarities]);

  useEffect(() => {
    localStorage.setItem('sortAttribute_itemSelection', sortAttribute);
  }, [sortAttribute]);

  useEffect(() => {
    localStorage.setItem('sortOrder_itemSelection', sortOrder);
  }, [sortOrder]);

  useEffect(() => {
    localStorage.setItem('selectedCollection_itemSelection', selectedCollection);
  }, [selectedCollection]);

  useEffect(() => {
    if (userAccount) {
      loadFilteredItems();
      checkRefreshClicks();
    }
  }, [userAccount]);

  useEffect(() => {
    if (userAccount) {
      loadFilteredItems();
    }
  }, [selectedCollection, selectedRarities, sortAttribute, sortOrder]);

  useEffect(() => {
    let cooldownInterval;
    if (isCooldown && cooldownTime > 0) {
      cooldownInterval = setInterval(() => {
        setCooldownTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (cooldownTime <= 0 && isCooldown) {
      clearInterval(cooldownInterval);
      setIsCooldown(false);
      setCooldownTime(0);
      setRefreshCount(0);
      localStorage.removeItem('refreshClicks_itemSelection');
    }
    return () => clearInterval(cooldownInterval);
  }, [isCooldown, cooldownTime]);

  const checkRefreshClicks = () => {
    const refreshClicks = JSON.parse(localStorage.getItem('refreshClicks_itemSelection')) || [];
    const now = Date.now();
    const validClicks = refreshClicks.filter((clickTime) => now - clickTime < 60000);
    localStorage.setItem('refreshClicks_itemSelection', JSON.stringify(validClicks));
    setRefreshCount(validClicks.length);
  };

  const loadFilteredItems = async () => {
    setIsLoading(true);
    try {
      const fetchedItems = await fetchFilteredNFTs(userAccount);
      const uniqueCollections = [...new Set(fetchedItems.map((item) => item.collection.collection_name))];
      setCollections(uniqueCollections);

      const itemsWithStatus = await Promise.all(
        fetchedItems.map(async (item) => {
          const isInRace = await checkItemInRace(item.asset_id);
          const { victoryChance, dropChance, xpBonus } = getChanceForItem(item);
          const rarity = item.data?.Rarity || null;
          return { ...item, inRace: isInRace, victoryChance, dropChance, xpBonus, Rarity: rarity };
        })
      );

      const filteredByCollection = selectedCollection
        ? itemsWithStatus.filter((item) => item.collection.collection_name === selectedCollection)
        : itemsWithStatus;

      const filteredByRarity = selectedRarities.length > 0
        ? filteredByCollection.filter((item) => selectedRarities.includes(item.Rarity))
        : filteredByCollection;

      const sortedItems = sortAttribute
        ? [...filteredByRarity].sort((a, b) => {
            const aValue = a[sortAttribute];
            const bValue = b[sortAttribute];
            if (aValue === undefined && bValue === undefined) return 0;
            if (aValue === undefined) return 1;
            if (bValue === undefined) return -1;
            return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
          })
        : filteredByRarity;

      setItems(sortedItems);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching filtered NFTs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = () => {
    if (isCooldown) return;
    const now = Date.now();
    const refreshClicks = JSON.parse(localStorage.getItem('refreshClicks_itemSelection')) || [];

    if (refreshCount >= MAX_REFRESH_CLICKS) {
      setIsCooldown(true);
      setCooldownTime(10);
      localStorage.setItem('refreshClicks_itemSelection', JSON.stringify(refreshClicks));
    } else {
      loadFilteredItems();
      const updatedClicks = [...refreshClicks, now];
      localStorage.setItem('refreshClicks_itemSelection', JSON.stringify(updatedClicks));
      setRefreshCount(refreshCount + 1);
    }
  };

  const isItemAlreadyUsedInOtherCar = (item) => {
    if (!allEquippedItemsGlobal || allEquippedItemsGlobal.length === 0) return false;
    const itemId = item.asset_id;
    for (let carItems of allEquippedItemsGlobal) {
      for (let equippedItem of carItems) {
        if (equippedItem && equippedItem.asset_id === itemId) {
          return true;
        }
      }
    }
    return false;
  };

  const isTemplateInSameCar = (item) => {
    if (!allEquippedItemsGlobal || allEquippedItemsGlobal.length === 0) return false;
    if (currentCarIndex == null || currentCarIndex < 0 || !allEquippedItemsGlobal[currentCarIndex]) return false;
    const templateId = item.template.template_id;
    const carItems = allEquippedItemsGlobal[currentCarIndex];
    return carItems.some(ci => ci && ci.template.template_id === templateId);
  };

  const canAddItem = (item) => {
    if (item.inRace) return false;
    if (isTemplateInSameCar(item)) return false;
    if (isItemAlreadyUsedInOtherCar(item)) return false;
    const templateId = item.template.template_id;
    const alreadySelectedTemplate = selectedItems.some(si => si.template.template_id === templateId);
    if (alreadySelectedTemplate) return false;
    return true;
  };

  const handleSelectItem = (item) => {
    if (!canAddItem(item)) return;
    const isAlreadySelected = selectedItems.some((selectedItem) => selectedItem.asset_id === item.asset_id);
    if (isAlreadySelected) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.asset_id !== item.asset_id));
    } else {
      if (selectedItems.length < maxItems) {
        setSelectedItems([...selectedItems, item]);
      }
    }
  };

  const handleEquip = () => {
    if (selectedItems.length === 0) {
      setShowNFTSelector(false);
      return;
    }

    // Verifica se há algum carro selecionado
    if (!allEquippedItemsGlobal || allEquippedItemsGlobal.length === 0 || currentCarIndex == null || currentCarIndex < 0 || !allEquippedItemsGlobal[currentCarIndex]) {
      setShowNFTSelector(false);
      setNotificationMessage('No car selected. Please select a car before equipping items.');
      setShowNotification(true);
      return;
    }

    const carItems = [...allEquippedItemsGlobal[currentCarIndex]];
    const emptySlots = [];
    for (let i = 0; i < carItems.length; i++) {
      if (carItems[i] === null) {
        emptySlots.push(i);
      }
    }

    const equips = [];
    if (currentItemIndex !== null) {
      equips.push({ item: selectedItems[0], slotIndex: currentItemIndex });
    } else {
      for (let i = 0; i < selectedItems.length && i < emptySlots.length; i++) {
        equips.push({ item: selectedItems[i], slotIndex: emptySlots[i] });
      }
    }

    handleEquipMultipleNFTs(equips, currentCarIndex);
    setShowNFTSelector(false);
  };

  const handleRandomSelect = () => {
    const availableItems = items.filter(item => canAddItem(item));
    const shuffled = availableItems.sort(() => 0.5 - Math.random());
    const usedTemplates = new Set();
    const selected = [];
    for (let it of shuffled) {
      if (selected.length >= maxItems) break;
      if (!usedTemplates.has(it.template.template_id)) {
        selected.push(it);
        usedTemplates.add(it.template.template_id);
      }
    }
    setSelectedItems(selected);
  };

  const handleBestItemsSelect = (attribute) => {
    const filtered = items.filter(item => canAddItem(item));
    const sorted = [...filtered].sort((a, b) => b[attribute] - a[attribute]);
    const usedTemplates = new Set();
    const selected = [];
    for (let it of sorted) {
      if (selected.length >= maxItems) break;
      if (!usedTemplates.has(it.template.template_id)) {
        selected.push(it);
        usedTemplates.add(it.template.template_id);
      }
    }
    setSelectedItems(selected);
  };

  const handleBalancedSelect = () => {
    const filtered = items.filter(item => canAddItem(item));

    const sortedByVictory = [...filtered].sort((a, b) => b.victoryChance - a.victoryChance);
    const sortedByDrop = [...filtered].sort((a, b) => b.dropChance - a.dropChance);
    const sortedByExp = [...filtered].sort((a, b) => b.xpBonus - a.xpBonus);

    const selected = [];
    const usedTemplates = new Set();

    const pools = [sortedByVictory, sortedByDrop, sortedByExp];
    let poolIndex = 0;

    while (selected.length < maxItems && (pools[0].length > 0 || pools[1].length > 0 || pools[2].length > 0)) {
      const pool = pools[poolIndex % 3];
      while (pool.length > 0) {
        const candidate = pool.shift();
        if (!usedTemplates.has(candidate.template.template_id) && canAddItem(candidate)) {
          selected.push(candidate);
          usedTemplates.add(candidate.template.template_id);
          break;
        }
      }
      poolIndex++;
    }

    setSelectedItems(selected);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalAttributes = selectedItems.reduce(
    (totals, item) => {
      return {
        win: totals.win + (item.victoryChance || 0),
        drop: totals.drop + (item.dropChance || 0),
        exp: totals.exp + (item.xpBonus || 0),
      };
    },
    { win: 0, drop: 0, exp: 0 }
  );

  return (
    <>
      <div className="item-selection-overlay" onClick={() => setShowNFTSelector(false)}></div>
      <div className="item-selection-modal">
        <div className="item-selection-header">
          <h2>Select Your Item</h2>
          <button className="item-selection-close-button" onClick={() => setShowNFTSelector(false)}>✖</button>
        </div>
        <button className="item-selection-refresh-button" onClick={handleRefresh} disabled={isCooldown}>
          {isCooldown ? `Wait... ${cooldownTime}s` : 'Refresh'}
        </button>

        <div className="item-selection-filters">
          <div className="filter-section">
            <h4>Filter by Collection:</h4>
            <select
              id="collection-select"
              value={selectedCollection}
              onChange={(e) => {
                setSelectedCollection(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value="">All Collections</option>
              {collections.map((collection) => (
                <option key={collection} value={collection}>
                  {collection}
                </option>
              ))}
            </select>
          </div>

          <div className="filter-section">
            <h4>Filter by Rarity:</h4>
            <div className="rarity-options">
              {Array.from(new Set(items.map(item => item.Rarity)))
                .filter(rarity => rarity)
                .map((rarity) => (
                  <label key={rarity} className="rarity-label">
                    <input
                      type="checkbox"
                      value={rarity}
                      checked={selectedRarities.includes(rarity)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRarities([...selectedRarities, rarity]);
                        } else {
                          setSelectedRarities(selectedRarities.filter(r => r !== rarity));
                        }
                        setCurrentPage(1);
                      }}
                    />
                    {rarity}
                  </label>
                ))}
            </div>
          </div>

          <div className="filter-section">
            <h4>Sort by Attribute:</h4>
            <div className="sort-options">
              <select
                value={sortAttribute}
                onChange={(e) => {
                  setSortAttribute(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">None</option>
                <option value="victoryChance">Win</option>
                <option value="dropChance">Drop</option>
                <option value="xpBonus">Exp</option>
              </select>
              <select
                value={sortOrder}
                onChange={(e) => {
                  setSortOrder(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>

          <div className="filter-section">
            <h4>Automatic Selection:</h4>
            <div className="auto-selection-buttons">
              <button onClick={handleRandomSelect}>Random</button>
              <button onClick={() => handleBestItemsSelect('dropChance')}>Best Drop</button>
              <button onClick={() => handleBestItemsSelect('victoryChance')}>Best Win</button>
              <button onClick={() => handleBestItemsSelect('xpBonus')}>Best Exp</button>
              <button onClick={handleBalancedSelect}>Balanced</button>
            </div>
          </div>
        </div>

        <div className="item-selection-grid-container">
          {isLoading ? (
            <div className="item-selection-loading-container">
              <p>Loading Items...</p>
            </div>
          ) : (
            <div className="item-selection-grid">
              {currentItems.map((item) => {
                const { victoryChance, dropChance, xpBonus } = item;
                const isSelected = selectedItems.some((selectedItem) => selectedItem.asset_id === item.asset_id);
                const disabled = item.inRace || !canAddItem(item);
                return (
                  <div
                    key={`${item.asset_id}-${item.template.template_id}`}
                    className={`item-selection-item 
                      ${item.collection.collection_name.toLowerCase() === 'pixelracesgo' ? 'wax-highlight' : ''} 
                      ${item.inRace ? 'in-race' : ''} 
                      ${disabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''}`}
                    onClick={() => !disabled && handleSelectItem(item)}
                    style={{
                      cursor: disabled ? 'not-allowed' : 'pointer',
                      pointerEvents: item.inRace ? 'none' : 'auto',
                    }}
                  >
                    <div className="item-selection-content">
                      {item.inRace && <div className="status-overlay in-race">Racing</div>}
                      <div className="item-selection-image-wrapper">
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                          alt={item.name}
                          className="item-selection-image"
                        />
                      </div>
                      <div className="item-selection-name">{item.name}</div>
                      <div className="item-selection-id">{item.asset_id}</div>
                      <div className="item-chances">
                        <p>WIN: {victoryChance}%</p>
                        <p>DROP: {dropChance}%</p>
                        <p>EXP: {xpBonus}%</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {totalPages > 1 && (
          <div className="item-selection-pagination">
            {[...Array(totalPages).keys()].map((number) => (
              <button
                key={number + 1}
                onClick={() => paginate(number + 1)}
                className={`item-selection-pagination-button ${
                  currentPage === number + 1 ? 'item-selection-pagination-button-active' : ''
                }`}
              >
                {number + 1}
              </button>
            ))}
          </div>
        )}

        {selectedItems.length > 0 && (
          <div className="selected-items-container">
            <h3>Selected Items:</h3>
            <div className="selected-items-grid">
              {selectedItems.map((item) => (
                <div key={item.asset_id} className="selected-item" onClick={() => handleSelectItem(item)}>
                  <img
                    src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                    alt={item.name}
                    className="selected-item-image"
                  />
                  <div className="selected-item-name">{item.name}</div>
                </div>
              ))}
            </div>
            <div className="selected-items-totals">
              <h4>Totals:</h4>
              <p>WIN: {totalAttributes.win}%</p>
              <p>DROP: {totalAttributes.drop}%</p>
              <p>EXP: {totalAttributes.exp}%</p>
            </div>
          </div>
        )}

        <button className="equip-button" onClick={handleEquip} disabled={selectedItems.length === 0}>
          Equip
        </button>
      </div>
    </>
  );
};

export default ItemSelection;
