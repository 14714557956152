// server/components/CarDisplay.js

import React, { useEffect, useState } from 'react';
import { 
  checkCarRaceStatus, 
  saveBuildToDB, 
  getBuildsFromDB, 
  loadBuildFromDB, 
  deleteBuildFromDB, 
  fetchNFTs, 
  fetchFilteredNFTs 
} from '../utils/api';
import '../styles/carDisplay.css'; // Ensure the CSS file is correctly imported

const CarDisplay = ({
  userAccount,
  selectedCars,
  setSelectedCars,
  allEquippedItems,
  setAllEquippedItems,
  setShowItemSelection,
  setNotificationMessage,
  setShowNotification,
  handleSelectCar,
  handleOpenBuildPanel,
  handleAutoSelect,
  selectedCarIndexForAttributes,
  setSelectedCarIndexForAttributes,
  showBuildPanel,
  setShowBuildPanel,
  buildName,
  setBuildName,
  savedBuilds,
  setSavedBuilds,
  checkAvailability,
  clearAllPresets
}) => {
  const [raceStatuses, setRaceStatuses] = useState([]);
  const [loadingBuildName, setLoadingBuildName] = useState(null);
  const [isAutoLoading, setIsAutoLoading] = useState(false);
  const [isLoadBuildLoading, setIsLoadBuildLoading] = useState(false);

  useEffect(() => {
    const fetchRaceStatuses = async () => {
      if (selectedCars && selectedCars.length > 0) {
        try {
          const statuses = await Promise.all(
            selectedCars.map(async (car) => {
              if (car && car.asset_id) {
                const status = await checkCarRaceStatus(car.asset_id);
                return status[0];
              } else {
                return null;
              }
            })
          );
          setRaceStatuses(statuses);
        } catch (error) {
          console.error('Failed to fetch race statuses:', error);
        }
      } else {
        setRaceStatuses([]);
      }
    };
    fetchRaceStatuses();
  }, [selectedCars]);

  const handleRemoveCarSlot = (index) => {
    setAllEquippedItems((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
    setSelectedCars((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
    if (selectedCarIndexForAttributes === index) {
      setSelectedCarIndexForAttributes(null);
    }
  };

  const handleRemoveNFT = (carIndex, itemIndex) => {
    setAllEquippedItems((prev) => {
      const updated = [...prev];
      const carItems = [...updated[carIndex]];
      carItems[itemIndex] = null;
      updated[carIndex] = carItems;
      return updated;
    });
  };

  const handleEquipNFTClick = (carIndex, itemIndex) => {
    if (!selectedCars || selectedCars.length === 0) {
      setShowItemSelection({ carIndex: 0, itemIndex: null });
      return;
    }
    setShowItemSelection({ carIndex, itemIndex: null });
  };

  const handleCarSlotClick = (carIndex) => {
    if (selectedCarIndexForAttributes !== carIndex) {
      setSelectedCarIndexForAttributes(carIndex);
    }
  };

  const canSelectMoreCars = selectedCars.length < 10;

  const handleClearAll = async () => {
    await clearAllPresets();
  };

  useEffect(() => {
    const loadBuilds = async () => {
      if (userAccount && showBuildPanel) {
        try {
          const builds = await getBuildsFromDB(userAccount);
          const buildNames = builds.map(b => b.buildName);
          setSavedBuilds(buildNames);
        } catch (error) {
          console.error('Error loading builds:', error);
        }
      }
    };
    loadBuilds();
  }, [userAccount, showBuildPanel, setSavedBuilds]);

  const handleAutoSelectWithLoading = async () => {
    setIsAutoLoading(true);
    try {
      await handleAutoSelect();
      setNotificationMessage('Auto selection completed successfully!');
      setShowNotification(true);
    } catch (error) {
      console.error('Error in auto selection:', error);
      setNotificationMessage('Auto selection failed.');
      setShowNotification(true);
    }
    setIsAutoLoading(false);
  };

  const loadBuild = async (bn) => {
    if (!userAccount) {
      setNotificationMessage('User not logged in.');
      setShowNotification(true);
      return;
    }
    setIsLoadBuildLoading(true);
    setLoadingBuildName(bn);
    try {
      const buildData = await loadBuildFromDB(userAccount, bn);

      const userNfts = await fetchNFTs(userAccount);
      const userCarAssetIds = userNfts.map(n => n.asset_id);

      const userItems = await fetchFilteredNFTs(userAccount);
      const userItemAssetIds = userItems.map(i => i.asset_id);

      const filteredCars = (buildData.cars || []).filter(car => userCarAssetIds.includes(car.asset_id));

      const filteredItems = (buildData.items || []).map((carItems, carIndex) => {
        if (!filteredCars[carIndex]) {
          return [null, null, null];
        }
        return carItems.map(item => {
          if (item && userItemAssetIds.includes(item.asset_id)) {
            return item;
          } else {
            return null;
          }
        });
      });

      const finalFilteredItems = filteredItems.slice(0, filteredCars.length);

      setSelectedCars(filteredCars);
      setAllEquippedItems(finalFilteredItems);

      if (filteredCars.length === 1) {
        setSelectedCarIndexForAttributes(0);
      } else {
        setSelectedCarIndexForAttributes(null);
      }

      setNotificationMessage('Build loaded successfully!');
      setShowNotification(true);
    } catch (error) {
      console.error('Error loading build:', error);
      setNotificationMessage('Failed to load build.');
      setShowNotification(true);
    } finally {
      setLoadingBuildName(null);
      setIsLoadBuildLoading(false);
    }
  };

  const removeBuild = async (bn) => {
    if (!userAccount) {
      setNotificationMessage('User not logged in.');
      setShowNotification(true);
      return;
    }
    try {
      await deleteBuildFromDB(userAccount, bn);
      const builds = await getBuildsFromDB(userAccount);
      const buildNames = builds.map(b => b.buildName);
      setSavedBuilds(buildNames);
      setNotificationMessage('Build deleted successfully!');
      setShowNotification(true);
    } catch (error) {
      setNotificationMessage('Failed to delete build.');
      setShowNotification(true);
    }
  };

  const renderBuildPanel = () => (
    showBuildPanel && (
      <div className="build-panel">
        <button className="close-build-panel-btn" onClick={() => setShowBuildPanel(false)}>X</button>
        <input 
          type="text" 
          placeholder="Build Name" 
          value={buildName} 
          onChange={e => { setBuildName(e.target.value); }} 
        />
        <button onClick={saveBuild}>Save Build</button>
        <div className="build-list">
          <h4>Saved Builds:</h4>
          {(!savedBuilds || savedBuilds.length === 0) ? (
            <div>No builds saved.</div>
          ) : (
            savedBuilds.map((bn) => (
              <div key={bn} className="build-list-item">
                <span>{bn}</span>
                <div>
                  <button 
                    onClick={() => loadBuild(bn)}
                    disabled={isLoadBuildLoading && loadingBuildName === bn}
                  >
                    {isLoadBuildLoading && loadingBuildName === bn ? 'Loading...' : 'Load'}
                  </button>
                  <button 
                    onClick={() => removeBuild(bn)} 
                    disabled={isLoadBuildLoading}
                  >
                    X
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    )
  );

  const saveBuild = async () => {
    if (!buildName) {
      setNotificationMessage('Please enter a build name.');
      setShowNotification(true);
      return;
    }
    const buildNameRegex = /^[A-Za-z0-9 ]{1,10}$/;
    if (!buildNameRegex.test(buildName)) {
      setNotificationMessage('Build name must be up to 10 characters and contain only letters, numbers and spaces.');
      setShowNotification(true);
      return;
    }

    if (!userAccount) {
      setNotificationMessage('User not logged in.');
      setShowNotification(true);
      return;
    }
    try {
      await saveBuildToDB(userAccount, buildName, selectedCars, allEquippedItems);
      setNotificationMessage('Build saved successfully!');
      setShowNotification(true);
      const builds = await getBuildsFromDB(userAccount);
      const buildNames = builds.map(b => b.buildName);
      setSavedBuilds(buildNames);
    } catch (error) {
      setNotificationMessage(error.message || 'Failed to save build.');
      setShowNotification(true);
    }
  };

  const renderSingleCarMode = () => {
    const hasCar = selectedCars.length === 1;
    const selectedCar = hasCar ? selectedCars[0] : null;
    const equippedNFTs = hasCar ? (allEquippedItems[0] || [null, null, null]) : [null, null, null];
    const raceStatus = hasCar ? raceStatuses[0] : null;

    return (
      <div className="car-display">
        <div className="auto-build-buttons">
          <button className="auto-button" onClick={handleAutoSelectWithLoading} disabled={isAutoLoading}>
            Auto
          </button>
          <button className="build-button" onClick={() => setShowBuildPanel(!showBuildPanel)}>
            Build
          </button>
          <button className="build-button green" onClick={() => checkAvailability()}>
            Check
          </button>
        </div>
        {renderBuildPanel()}

        <div className="car-placeholder" onClick={handleSelectCar}>
          {hasCar ? (
            <img src={`https://atomichub-ipfs.com/ipfs/${selectedCar.template.immutable_data.img2}`} alt="Selected Car" className="car-image" />
          ) : (
            <img src="../assets/carmodelselected.png" alt="Car Placeholder" className="car-image" />
          )}
          {hasCar && raceStatus && raceStatus.ongoing && (
            <div className="race-status">In Use</div>
          )}
          {!hasCar && canSelectMoreCars && (
            <div className="select-car-overlay">Select Car</div>
          )}
          {hasCar && canSelectMoreCars && (
            <div className="select-car-overlay">Add Car</div>
          )}
        </div>

        <div className="items-area">
          <button 
            className="items-button large-text" 
            onClick={(e)=>{e.stopPropagation(); setShowItemSelection({carIndex:0,itemIndex:null})}}
            disabled={isAutoLoading || isLoadBuildLoading}
          >
            <span className="items-text">Items</span>
          </button>
          <div className="equipped-nfts">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="equipped-nft">
                {equippedNFTs[index] ? (
                  <>
                    <img src={`https://atomichub-ipfs.com/ipfs/${equippedNFTs[index].data.img}`} alt="Equipped NFT" className="nft-image" />
                    <button className="remove-nft-button" onClick={(e) => { e.stopPropagation(); handleRemoveNFT(0, index); }}>X</button>
                  </>
                ) : (
                  <div className="empty-slot" onClick={(e) => { e.stopPropagation(); handleEquipNFTClick(0, index); }}>+</div>
                )}
              </div>
            ))}
          </div>
        </div>

        <button className="clear-all-button" onClick={handleClearAll} disabled={isAutoLoading || isLoadBuildLoading}>
          Clear All
        </button>

        {/* Loading Spinner Overlay */}
        {(isAutoLoading || isLoadBuildLoading) && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    );
  };

  const renderMultipleCarsMode = () => {
    return (
      <div className="car-display">
        <div className="auto-build-buttons">
          <button className="auto-button" onClick={handleAutoSelectWithLoading} disabled={isAutoLoading}>
            Auto
          </button>
          <button className="build-button" onClick={() => setShowBuildPanel(!showBuildPanel)}>
            Build
          </button>
          <button className="build-button green" onClick={() => checkAvailability()}>
            Check
          </button>
        </div>
        {renderBuildPanel()}
        <div className="multi-cars-container">
          {selectedCars.map((car, carIndex) => {
            const equippedNFTs = allEquippedItems[carIndex] || [null, null, null];
            const raceStatus = raceStatuses[carIndex];
            return (
              <div className="car-slot" key={carIndex} onClick={() => handleCarSlotClick(carIndex)}>
                <button className="remove-car-button" onClick={(e) => { e.stopPropagation(); handleRemoveCarSlot(carIndex); }}>X</button>
                <div className="car-image-slot">
                  {car && car.template && car.template.immutable_data ? (
                    <img src={`https://atomichub-ipfs.com/ipfs/${car.template.immutable_data.img2}`} alt="Car" className="car-image" />
                  ) : (
                    <img src="../assets/carmodelselected.png" alt="Car Placeholder" className="car-image" />
                  )}
                  {raceStatus && raceStatus.ongoing && <div className="race-status">In Use</div>}
                </div>

                <div className="car-items">
                  {[...Array(3)].map((_, itemIndex) => (
                    <div key={itemIndex} className="equipped-nft">
                      {equippedNFTs[itemIndex] ? (
                        <>
                          <img src={`https://atomichub-ipfs.com/ipfs/${equippedNFTs[itemIndex].data.img}`} alt="Equipped NFT" className="nft-image" />
                          <button className="remove-nft-button" onClick={(e) => { e.stopPropagation(); handleRemoveNFT(carIndex, itemIndex); }}>X</button>
                        </>
                      ) : (
                        <div className="empty-slot" onClick={(e) => { e.stopPropagation(); handleEquipNFTClick(carIndex, itemIndex); }}>+</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        {canSelectMoreCars && (
          <div style={{textAlign:'center', marginTop:'10px'}}>
            <button className="items-button large-text" onClick={handleSelectCar} disabled={isAutoLoading || isLoadBuildLoading}>
              <span className="items-text">Add Car</span>
            </button>
          </div>
        )}
        <button className="clear-all-button" onClick={handleClearAll} disabled={isAutoLoading || isLoadBuildLoading}>
          Clear All
        </button>

        {/* Loading Spinner Overlay */}
        {(isAutoLoading || isLoadBuildLoading) && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    );
  };

  if (selectedCars.length === 0 || selectedCars.length === 1) {
    return renderSingleCarMode();
  } else {
    return renderMultipleCarsMode();
  }
};

export default CarDisplay;
