import React, { useState, useEffect } from 'react';
import { createItem, fetchItems, deleteItem, updateItem } from '../../utils/api';
import Cookies from 'js-cookie';

const ItemManagement = () => {
  const [newItem, setNewItem] = useState({
    collectionName: '',
    templateId: '',
    victoryChance: 0,
    dropChance: 0,
    xpBonus: 0,
    imageUrl: '', // Campo para a URL da imagem
    partnerLink: '', // Campo para o link do parceiro
    status: 'working' // Campo de status da coleção
  });
  const [items, setItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // Novo estado para edição
  const [editingItemId, setEditingItemId] = useState(null); // Armazenar o ID do item em edição

  useEffect(() => {
    const loadItems = async () => {
      const token = Cookies.get('authToken');
      const fetchedItems = await fetchItems(token);
      setItems(fetchedItems);
    };
    loadItems();
  }, []);

  const handleItemChange = (event) => {
    const { name, value, type, checked } = event.target;
    setNewItem({
      ...newItem,
      [name]: type === 'checkbox' ? checked : value // Atualizar com base no tipo de input
    });
  };

  const handleCreateOrUpdateItem = async () => {
    const token = Cookies.get('authToken');
    if (isEditing) {
      await updateItem(editingItemId, newItem, token); // Atualizar item existente
    } else {
      await createItem(newItem, token); // Criar novo item
    }

    setNewItem({
      collectionName: '',
      templateId: '',
      victoryChance: 0,
      dropChance: 0,
      xpBonus: 0,
      imageUrl: '',
      partnerLink: '', // Resetar o campo de link do parceiro
      status: 'working' // Resetar o campo de status
    });
    setIsEditing(false); // Resetar estado de edição
    setEditingItemId(null); // Limpar ID do item em edição

    const itemsData = await fetchItems(token);
    setItems(itemsData);
  };

  const handleEditItem = (item) => {
    setIsEditing(true); // Ativar modo de edição
    setEditingItemId(item._id); // Armazenar o ID do item em edição
    setNewItem({
      collectionName: item.collectionName,
      templateId: item.templateId,
      victoryChance: item.victoryChance,
      dropChance: item.dropChance,
      xpBonus: item.xpBonus,
      imageUrl: item.imageUrl,
      partnerLink: item.partnerLink, // Carregar link do parceiro para edição
      status: item.status
    });
  };

  const handleDeleteItem = async (itemId) => {
    const token = Cookies.get('authToken');
    await deleteItem(itemId, token);

    const itemsData = await fetchItems(token);
    setItems(itemsData);
  };

  return (
    <div>
      <h3>{isEditing ? 'Edit Item' : 'Create Item'}</h3>
      <div>
        <label>Collection Name:
          <input name="collectionName" value={newItem.collectionName} onChange={handleItemChange} placeholder="Collection Name" />
        </label>
      </div>
      <div>
        <label>Template ID:
          <input name="templateId" value={newItem.templateId} onChange={handleItemChange} placeholder="Template ID" />
        </label>
      </div>
      <div>
        <label>Victory Chance (%):
          <input name="victoryChance" type="number" value={newItem.victoryChance} onChange={handleItemChange} placeholder="Victory Chance" />
        </label>
      </div>
      <div>
        <label>Drop Chance (%):
          <input name="dropChance" type="number" value={newItem.dropChance} onChange={handleItemChange} placeholder="Drop Chance" />
        </label>
      </div>
      <div>
        <label>XP Bonus (%):
          <input name="xpBonus" type="number" value={newItem.xpBonus} onChange={handleItemChange} placeholder="XP Bonus" />
        </label>
      </div>
      <div>
        <label>Image URL:
          <input name="imageUrl" value={newItem.imageUrl} onChange={handleItemChange} placeholder="Image URL" />
        </label>
      </div>
      <div>
        <label>Partner Website Link: 
          <input name="partnerLink" value={newItem.partnerLink} onChange={handleItemChange} placeholder="Partner Website Link" />
        </label>
      </div>
      <div>
  <label>
    Is Collection:
    <input 
      name="isCollection" 
      type="checkbox" 
      checked={newItem.isCollection} 
      onChange={handleItemChange} 
    />
  </label>
</div>
      <div>
        <label>Status:
          <select name="status" value={newItem.status} onChange={handleItemChange}>
            <option value="working">Working</option>
            <option value="paused">Paused</option>
          </select>
        </label>
      </div>
      <button onClick={handleCreateOrUpdateItem}>
        {isEditing ? 'Update Item' : 'Create Item'}
      </button>
      <ul>
        {items.map((item) => (
          <li key={item._id}>
            {item.collectionName} - {item.status} - {item.isCollection ? 'Collection' : 'Not a Collection'}
            <img src={item.imageUrl} alt={item.collectionName} style={{ width: '50px' }} />
            <br />
            <a href={item.partnerLink} target="_blank" rel="noopener noreferrer">Visit Partner</a>
            <button onClick={() => handleEditItem(item)}>Edit</button>
            <button onClick={() => handleDeleteItem(item._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ItemManagement;
