import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
    fetchNFTs, checkStake, createStake, getStakeCoins, collectStakeCoins,
    removeStake, fetchStakeConfig, fetchNFTDetails, checkCarInRace
} from '../utils/api';
import './Stake.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from 'react-loader-spinner';

const Stake = () => {
    const { userAccount } = useAuth();
    const [nfts, setNfts] = useState([]);
    const [selectedNfts, setSelectedNfts] = useState([]);
    const [stakedNfts, setStakedNfts] = useState([]);
    const [stakedNftDetails, setStakedNftDetails] = useState([]);
    const [selectedStakedNfts, setSelectedStakedNfts] = useState([]);
    const [stakeCoins, setStakeCoins] = useState(0);
    const [stakeConfig, setStakeConfig] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);
    const [isCollectCooldown, setIsCollectCooldown] = useState(false);
    const [collectCooldownTime, setCollectCooldownTime] = useState(0);
    const [isRefreshCooldown, setIsRefreshCooldown] = useState(false);
    const [refreshCooldownTime, setRefreshCooldownTime] = useState(0);

    const [walletPage, setWalletPage] = useState(1);
    const [stakePage, setStakePage] = useState(1);
    const itemsPerPage = 50;

    const MAX_REFRESH_CLICKS = 5;
    const REFRESH_COOLDOWN_DURATION = 10; // 10 segundos
    const COINS_COLLECTION_INTERVAL = 12 * 60 * 60; // 12 horas em segundos

    useEffect(() => {
        if (userAccount) {
            loadStakedNfts();
            checkRefreshClicks();
        } else {
            loadStakeConfig();
        }
    }, [userAccount]);

    useEffect(() => {
        if (userAccount && stakedNfts.length === 0 && stakedNftDetails.length > 0) {
            toast.info("You have no stake and balance, stake your car for coins.");
        }
    }, [userAccount, stakedNfts, stakedNftDetails]);

    useEffect(() => {
        if (!userAccount) return;

        let collectInterval;
        let refreshInterval;

        if (isCollectCooldown && collectCooldownTime > 0) {
            collectInterval = setInterval(() => {
                setCollectCooldownTime(prevTime => prevTime - 1);
            }, 1000);
        } else if (collectCooldownTime <= 0 && isCollectCooldown) {
            clearInterval(collectInterval);
            setIsCollectCooldown(false);
            setCollectCooldownTime(0);
        }

        if (isRefreshCooldown && refreshCooldownTime > 0) {
            refreshInterval = setInterval(() => {
                setRefreshCooldownTime(prevTime => prevTime - 1);
            }, 1000);
        } else if (refreshCooldownTime <= 0 && isRefreshCooldown) {
            clearInterval(refreshInterval);
            setIsRefreshCooldown(false);
            setRefreshCooldownTime(0);
            setRefreshCount(0);
            localStorage.removeItem(`refreshClicks_${userAccount}`); // Remove refresh count after cooldown
        }

        return () => {
            clearInterval(collectInterval);
            clearInterval(refreshInterval);
        };
    }, [isCollectCooldown, collectCooldownTime, isRefreshCooldown, refreshCooldownTime, userAccount]);

    const checkRefreshClicks = () => {
        if (!userAccount) return;
        const refreshClicks = JSON.parse(localStorage.getItem(`refreshClicks_${userAccount}`)) || [];
        const now = Date.now();
        const validClicks = refreshClicks.filter(clickTime => now - clickTime < 60000); // 1 minute
        localStorage.setItem(`refreshClicks_${userAccount}`, JSON.stringify(validClicks));
        setRefreshCount(validClicks.length);
    };

    const loadNfts = async (staked) => {
        if (!userAccount) return;
        setIsLoading(true);
        try {
            const userNfts = await fetchNFTs(userAccount);
            const availableNfts = await Promise.all(
                userNfts.map(async nft => {
                    const isInRace = await checkCarInRace(nft.asset_id);
                    return {
                        ...nft,
                        inRace: isInRace.inRace
                    };
                })
            );
            const filteredNfts = availableNfts.filter(nft => !staked.includes(nft.asset_id));
            setNfts(filteredNfts);
        } catch (error) {
            console.error('Error loading NFTs:', error);
            toast.error('Error loading NFTs. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const loadStakedNfts = async () => {
        if (!userAccount) return;
        setIsLoading(true);
        try {
            const staked = await checkStake();
            setStakedNfts(staked);

            const nftDetails = await Promise.all(staked.map(nftId => fetchNFTDetails(nftId)));
            setStakedNftDetails(nftDetails);

            loadNfts(staked);
            loadStakeCoins();
            loadStakeConfig();
        } catch (error) {
            console.error('Error loading staked NFTs:', error);
            toast.error('Error loading staked NFTs. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const loadStakeCoins = async () => {
        if (!userAccount) return;
        try {
            const { stakeCoins: coins, lastCollected } = await getStakeCoins(); 
            setStakeCoins(parseFloat(coins.$numberDecimal)); // Convertendo Decimal128 para número
    
            // Novo: Calcular e configurar o cronômetro
            if (lastCollected) {
                const now = new Date();
                const timeSinceLastCollect = (now - new Date(lastCollected)) / 1000; // segundos
                if (timeSinceLastCollect < COINS_COLLECTION_INTERVAL) {
                    setCollectCooldownTime(COINS_COLLECTION_INTERVAL - timeSinceLastCollect);
                    setIsCollectCooldown(true);
                }
            }
        } catch (error) {
            console.error('Error loading coin balance:', error);
            // Remover a notificação de erro
        }
    };

    const loadStakeConfig = async () => {
        try {
            const config = await fetchStakeConfig();

            // Converter campos Decimal128 para números
            const rewardValuesParsed = {};
            Object.keys(config.rewardValues).forEach((key) => {
                rewardValuesParsed[key] = parseFloat(config.rewardValues[key].$numberDecimal);
            });

            const saldoParsed = parseFloat(config.saldo.$numberDecimal);

            setStakeConfig({
                ...config,
                rewardValues: rewardValuesParsed,
                saldo: saldoParsed,
            });
        } catch (error) {
            console.error('Error loading stake configuration:', error);
            // Evita mostrar notificações de erro para usuários não conectados
            if (userAccount) {
                toast.error('Error loading stake configuration. Please try again.');
            }
        }
    };

    const handleCollectStakeCoins = async () => {
        if (!userAccount) return;
        try {
            if (stakeCoins > 0) { 
                const response = await collectStakeCoins(userAccount);
                if (response.error) {
                    if (response.message.includes("You can collect")) {
                        toast.info(response.message); // Mostra a mensagem de cooldown
                    } else {
                        toast.error(response.message); // Mostra outras mensagens de erro
                    }
                } else {
                    setStakeCoins(0); 
                    toast.success(`Successfully collected PXRS!`);
                    loadStakeCoins(); // Recarregar o saldo e o cronômetro
                }
            } else {
                toast.info('No coins available to collect.');
            }
        } catch (error) {
            console.error('Error collecting stake coins:', error);
        }
    };

    // Função para selecionar NFTs staked
    const handleSelectStaked = (nft) => {
        if (!userAccount) return;
        if (selectedNfts.length > 0) return;

        setSelectedStakedNfts(prevState => {
            if (prevState.includes(nft)) {
                return prevState.filter(item => item.asset_id !== nft.asset_id);
            } else {
                return [...prevState, nft];
            }
        });
    };

    // Função para selecionar NFTs
    const handleSelect = (nft) => {
        if (!userAccount) return;
        if (nft.inRace || stakedNfts.some(staked => staked.asset_id === nft.asset_id)) return;
        if (selectedStakedNfts.length > 0) return;

        setSelectedNfts(prevState => {
            if (prevState.includes(nft)) {
                return prevState.filter(item => item.asset_id !== nft.asset_id);
            } else {
                return [...prevState, nft];
            }
        });
    };

    const handleStake = async () => {
        if (!userAccount) return;
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            if (selectedNfts.length === 0) {
                toast.error('Please select at least one NFT to stake.');
                setIsSubmitting(false);
                return;
            }

            const nftsInRace = await Promise.all(
                selectedNfts.map(nft => checkCarInRace(nft.asset_id))
            );

            if (nftsInRace.some(nft => nft.inRace)) {
                toast.error('One or more selected NFTs are in a race. They cannot be staked.');
                setIsSubmitting(false);
                return;
            }

            const batchSize = 10;
            for (let i = 0; i < selectedNfts.length; i += batchSize) {
                const batch = selectedNfts.slice(i, i + batchSize);
                const nftIds = batch.map(nft => nft.asset_id);

                try {
                    await createStake(nftIds, userAccount);
                    toast.success(`Batch of ${batch.length} NFTs staked successfully!`);
                } catch (error) {
                    console.error(`Error processing NFT batch: ${error.message}`);
                    toast.error('Error processing an NFT batch. Some NFTs may not have been staked.');
                }
            }

            setSelectedNfts([]);
            loadStakedNfts();
        } catch (error) {
            console.error('Error staking NFTs:', error);
            toast.error('An error occurred while staking NFTs. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRemoveStake = async () => {
        if (!userAccount) return;
        try {
            const nftIds = selectedStakedNfts.map(nft => nft.asset_id);
            await removeStake(nftIds);
            toast.success('NFTs successfully unstaked!');
            setSelectedStakedNfts([]);
            loadStakedNfts();
        } catch (error) {
            console.error('Error removing NFTs from stake:', error);
            toast.error('An error occurred while unstaking NFTs. Please try again.');
        }
    };

    const handleSelectAllNfts = () => {
        if (!userAccount) return;
        const selectableNfts = currentNfts.filter(nft => !nft.inRace);
        setSelectedNfts(selectableNfts.length === selectedNfts.length ? [] : selectableNfts);
        setSelectedStakedNfts([]);
    };

    const handleSelectAllStakedNfts = () => {
        if (!userAccount) return;
        setSelectedStakedNfts(stakedNftDetails.length === selectedStakedNfts.length ? [] : stakedNftDetails);
        setSelectedNfts([]);
    };

    const handleRefreshAllNfts = () => {
        if (!userAccount) return;
        const now = Date.now();
        const refreshClicks = JSON.parse(localStorage.getItem(`refreshClicks_${userAccount}`)) || [];

        if (refreshCount >= MAX_REFRESH_CLICKS) {
            setIsRefreshCooldown(true);
            setRefreshCooldownTime(REFRESH_COOLDOWN_DURATION);
        } else {
            loadNfts(stakedNfts);
            loadStakedNfts();
            const updatedClicks = [...refreshClicks, now];
            localStorage.setItem(`refreshClicks_${userAccount}`, JSON.stringify(updatedClicks));
            setRefreshCount(refreshCount + 1);
        }
    };

    // Paginação
    const indexOfLastWalletItem = walletPage * itemsPerPage;
    const indexOfFirstWalletItem = indexOfLastWalletItem - itemsPerPage;
    const currentNfts = nfts.slice(indexOfFirstWalletItem, indexOfLastWalletItem);

    const indexOfLastStakeItem = stakePage * itemsPerPage;
    const indexOfFirstStakeItem = indexOfLastStakeItem - itemsPerPage;
    const currentStakedNfts = stakedNftDetails.slice(indexOfFirstStakeItem, indexOfLastStakeItem);

    const paginateWallet = (pageNumber) => setWalletPage(pageNumber);
    const paginateStake = (pageNumber) => setStakePage(pageNumber);

    const formatCooldownTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${h}h ${m}m ${s}s`;
    };

    return (
        <div className="stake-container">
            <div className="stake-info">
                <h1 className="stake-title">STAKE</h1>
                <div className="gauge-container">
                    <div className="gauge">
                        <div className="needle"></div>
                    </div>
                </div>
                {stakeConfig && (
                    <div className="info-grid">
                        <div className="stake-status highlight">
                            <p>Stake Status: {stakeConfig.status}</p>
                        </div>
                        <div className="max-coins highlight">
                            <p>Stake Balance: {stakeConfig.saldo.toFixed(2)} PXRS</p>
                        </div>
                        <div className="highlight-rarities">
                            <p>Earnings per rarity (hour):</p>
                            <ul>
                                <li>Ultimate: {stakeConfig.rewardValues.ultimate.toFixed(2)} coins</li>
                                <li>Exotic: {stakeConfig.rewardValues.exotic.toFixed(2)} coins</li>
                                <li>Mythic: {stakeConfig.rewardValues.mythic.toFixed(2)} coins</li>
                                <li>Legendary: {stakeConfig.rewardValues.legendary.toFixed(2)} coins</li>
                                <li>Epic: {stakeConfig.rewardValues.epic.toFixed(2)} coins</li>
                                <li>Rare: {stakeConfig.rewardValues.rare.toFixed(2)} coins</li>
                                <li>Uncommon: {stakeConfig.rewardValues.uncommon.toFixed(2)} coins</li>
                                <li>Common: {stakeConfig.rewardValues.common.toFixed(2)} coins</li>
                            </ul>
                        </div>
                    </div>
                )}
                {userAccount && (
    <>
        <h2 className="your-balance highlight">Your Balance: {stakeCoins.toFixed(2)} PXRS</h2>
        <h4 className="info-collect-stake">You can withdraw once every 12 hours.</h4>
        <button 
            onClick={handleCollectStakeCoins} 
            className="custom-collect-button"
            disabled={isCollectCooldown}
        >
            {isCollectCooldown ? `COOLDOWN: ${formatCooldownTime(collectCooldownTime)}` : 'COLLECT'}
        </button>
    </>
)}
                {!userAccount && (
                    <p className="highlight">Please log in to perform staking actions.</p>
                )}
            </div>

            {userAccount && (
                <div className="stake-section">
                    <div className="stake-subsection">
                        <h2>NFTs in staking</h2>
                        <button 
                            className={`custom-refresh-button-stake ${!userAccount && 'disabled'}`} 
                            onClick={userAccount ? handleRefreshAllNfts : null}
                            disabled={isRefreshCooldown || !userAccount}
                        >
                            {isRefreshCooldown ? `Wait... ${refreshCooldownTime}s` : 'Refresh NFTs'}
                        </button>
                        <button 
                            onClick={userAccount ? handleSelectAllStakedNfts : null} 
                            className={`custom-select-all-button ${!userAccount && 'disabled'}`}
                        >
                            Select All
                        </button>
                        <div className="nft-list-stake">
                            {isLoading ? (
                                <div className="nft-list-stake-loading-container">
                                    <div className="nft-list-stake-spinner-wrapper">
                                        <TailSpin height="100" width="100" color="#00ff00" ariaLabel="loading" />
                                    </div>
                                </div>
                            ) : (
                                currentStakedNfts.map((nft) => (
                                    <div
                                        key={nft.asset_id}
                                        className={`nft-item-stake ${selectedStakedNfts.includes(nft) ? 'selected' : ''}`}
                                        onClick={userAccount ? () => handleSelectStaked(nft) : null}
                                    >
                                        {nft.template && nft.template.immutable_data ? (
                                            <>
                                                <img src={`https://atomichub-ipfs.com/ipfs/${nft.template.immutable_data.img}`} alt={nft.template.immutable_data.name} className="nft-image-stake" />
                                                <div className="nft-name-stake">{nft.template.immutable_data.name}</div>
                                                <div className="nft-id-stake">ID: {nft.asset_id}</div>
                                                <div className="nft-rarity-stake">{nft.template.immutable_data['Car Rarity']}</div>
                                                <p className="in-stake-text-stake">Staked</p>
                                            </>
                                        ) : (
                                            <div className="nft-error">NFT data not available</div>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="pagination">
                            {[...Array(Math.ceil(stakedNftDetails.length / itemsPerPage)).keys()].map(number => (
                                <button
                                    key={number}
                                    onClick={() => paginateStake(number + 1)}
                                    className={`custom-pagination-button ${stakePage === number + 1 ? 'active' : ''}`}
                                >
                                    {number + 1}
                                </button>
                            ))}
                        </div>
                        {selectedStakedNfts.length > 0 && selectedNfts.length === 0 && (
                            <button onClick={handleRemoveStake} className="custom-remove-stake-button fixed-bottom">Remove NFTS</button>
                        )}
                    </div>

                    <div className="stake-subsection">
                        <h2>Wallet Inventory</h2>
                        <button 
                            onClick={userAccount ? handleRefreshAllNfts : null} 
                            className={`custom-refresh-button-stake ${!userAccount && 'disabled'}`}
                            disabled={isRefreshCooldown || !userAccount}
                        >
                            {isRefreshCooldown ? `Wait... ${refreshCooldownTime}s` : 'Refresh NFTs'}
                        </button>
                        <button 
                            onClick={userAccount ? handleSelectAllNfts : null} 
                            className={`custom-select-all-button ${!userAccount && 'disabled'}`}
                        >
                            Select All
                        </button>
                        <div className="nft-list-stake">
                            {isLoading ? (
                                <div className="nft-list-stake-loading-container">
                                    <div className="nft-list-stake-spinner-wrapper">
                                        <TailSpin height="100" width="100" color="#00ff00" ariaLabel="loading" />
                                    </div>
                                </div>
                            ) : (
                                currentNfts.map((nft) => (
                                    <div
                                        key={nft.asset_id}
                                        className={`nft-item-stake ${selectedNfts.includes(nft) ? 'selected' : ''} ${nft.inRace ? 'in-race' : ''} ${stakedNfts.includes(nft.asset_id) ? 'in-stake' : ''}`}
                                        onClick={userAccount ? () => handleSelect(nft) : null}
                                    >
                                        {nft.template && nft.template.immutable_data ? (
                                            <>
                                                <img src={`https://atomichub-ipfs.com/ipfs/${nft.template.immutable_data.img}`} alt={nft.template.immutable_data.name} className="nft-image-stake" />
                                                <div className="nft-name-stake">{nft.template.immutable_data.name}</div>
                                                <div className="nft-id-stake">ID: {nft.asset_id}</div>
                                                <div className="nft-rarity-stake">{nft.template.immutable_data['Car Rarity']}</div>                                                
                                                {nft.inRace ? <p className="in-race-text-stake">Racing</p> : stakedNfts.includes(nft.asset_id) && <p className="in-stake-text-stake">Staked</p>}
                                            </>
                                        ) : (
                                            <div className="nft-error">NFT data not available</div>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="pagination">
                            {[...Array(Math.ceil(nfts.length / itemsPerPage)).keys()].map(number => (
                                <button
                                    key={number}
                                    onClick={() => paginateWallet(number + 1)}
                                    className={`custom-pagination-button ${walletPage === number + 1 ? 'active' : ''}`}
                                >
                                    {number + 1}
                                </button>
                            ))}
                        </div>
                        {selectedNfts.length > 0 && selectedStakedNfts.length === 0 && (
                            <button 
                                onClick={handleStake} 
                                disabled={isSubmitting} 
                                className={`custom-stake-button fixed-bottom ${isSubmitting ? 'disabled' : ''}`}
                            >
                                {isSubmitting ? 'Staking...' : 'Stake NFTs'}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Stake;
