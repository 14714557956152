// server/components/MapDetails.js

import React, { useState } from 'react';
import '../styles/mapDetails.css';

const MapDetails = ({ selectedMap }) => {
  const [hoveredNftIndex, setHoveredNftIndex] = useState(null);
  const [hoveredBoostIndex, setHoveredBoostIndex] = useState(null);

  const handleMouseEnterNft = (index) => {
    setHoveredNftIndex(index);
  };

  const handleMouseLeaveNft = () => {
    setHoveredNftIndex(null);
  };

  const handleMouseEnterBoost = (index) => {
    setHoveredBoostIndex(index);
  };

  const handleMouseLeaveBoost = () => {
    setHoveredBoostIndex(null);
  };

  const formatRaceTime = (seconds) => {
    if (seconds < 60) {
      return `${seconds}s`;
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes < 60) {
      return `${minutes}m ${remainingSeconds}s`;
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours < 24) {
      return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`;
    }

    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    return `${days}d ${remainingHours}h ${remainingMinutes}s`;
  };

  const totalCoins = selectedMap.totalCoins && selectedMap.totalCoins.$numberDecimal
    ? parseFloat(selectedMap.totalCoins.$numberDecimal)
    : 0;

  return (
    <div className="custom-map-details">
      <div className="custom-map-selected">{selectedMap.name}</div>

      <div className="custom-map-info">
        <h4>Map Details:</h4>

        <div className="custom-map-description">
          <strong>Description:</strong> {selectedMap.description || 'No description available'}
        </div>

        <div className="custom-map-race-time">Race Time: {formatRaceTime(selectedMap.raceTime)}</div>
        <div className="custom-map-racers">Racers: {selectedMap.bots}</div>
        <div className="custom-map-total-coins">
          Total Coins: {!isNaN(totalCoins) ? totalCoins.toFixed(2) : '0.00'}
        </div>

        {selectedMap.nfts && selectedMap.nfts.length > 0 && (
          <div className="nfts-available-text">NFTs Available</div>
        )}

        <div className="custom-nft-slot-container">
          {selectedMap.nfts && selectedMap.nfts.map((nft, index) => (
            <div
              key={index}
              className="custom-nft-slot"
              onMouseEnter={() => handleMouseEnterNft(index)}
              onMouseLeave={handleMouseLeaveNft}
            >
              {nft.img ? (
                <img src={`https://atomichub-ipfs.com/ipfs/${nft.img}`} alt={nft.name} className="custom-nft-image" />
              ) : (
                <div>Image not available</div>
              )}
              <div className="custom-nft-name">{nft.name || 'Name not available'}</div>

              {hoveredNftIndex === index && nft.attributes && (
                <div className="mapdetails-nft-tooltip">
                  <div className="mapdetails-nft-tooltip-header">Attributes</div>
                  <ul className="mapdetails-nft-tooltip-list">
                    {nft.attributes
                      .filter(attr => attr.trait_type !== 'img' && attr.trait_type !== 'img2')
                      .map((attr, i) => (
                        <li key={i} className="mapdetails-nft-tooltip-item">
                          <strong className="attribute-name">{attr.trait_type}</strong>: <span className="attribute-value">{attr.value}</span>
                        </li>
                      ))}
                  </ul>
                  <div className="mapdetails-nft-drop-info">
                    <div><strong>Max Drop:</strong> {nft.max_drop}</div>
                    <div><strong>Dropped Count:</strong> {nft.dropped_count}</div>
                    <div><strong>Remaining Drops:</strong> {nft.max_drop - nft.dropped_count}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {selectedMap.boosted_templates && selectedMap.boosted_templates.length > 0 && (
          <div className="boosted-templates-container">
            <div className="boosted-templates-text">Boosted Templates</div>
            <div className="custom-nft-slot-container">
              {selectedMap.boosted_templates.map((boost, index) => (
                <div 
                  key={index} 
                  className="custom-nft-slot boosted-slot"
                  onMouseEnter={() => handleMouseEnterBoost(index)}
                  onMouseLeave={handleMouseLeaveBoost}
                >
                  {boost.img ? (
                    <img src={`https://atomichub-ipfs.com/ipfs/${boost.img}`} alt={boost.name || boost.template_id} className="custom-nft-image" />
                  ) : (
                    <div>Image not available</div>
                  )}
                  <div className="custom-nft-name">{boost.name || boost.template_id}</div>

                  {hoveredBoostIndex === index && (
                    <div className="mapdetails-nft-tooltip">
                      <div className="mapdetails-nft-tooltip-header">Boosts</div>
                      <ul className="mapdetails-nft-tooltip-list">
                        <li className="mapdetails-nft-tooltip-item">
                          <strong>Win Boost:</strong> {boost.boostWin}
                        </li>
                        <li className="mapdetails-nft-tooltip-item">
                          <strong>Drop Boost:</strong> {boost.boostDrop}
                        </li>
                        <li className="mapdetails-nft-tooltip-item">
                          <strong>Exp Boost:</strong> {boost.boostExp}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default MapDetails;
