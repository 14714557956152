// server/components/RewardsModal.js

import React, { useState, useEffect } from 'react'; 
import '../styles/rewardsModal.css';
import { collectRewards, fetchMaps, getRewards } from '../utils/api';
import seasontrophie from '../assets/seasontrophie.png';

const RewardsModal = ({
  showRewards,
  setShowRewards,
  userAccount,
  setNotificationMessage,
  setShowNotification,
  fetchPXRS,
}) => {
  const [loading, setLoading] = useState(false);
  const [maps, setMaps] = useState({});
  const [rewardsWithLogs, setRewardsWithLogs] = useState([]);
  const [expandedRewards, setExpandedRewards] = useState({});

  const fetchMapData = async () => {
    try {
      const mapsData = await fetchMaps();
      const mapsDict = mapsData.reduce((acc, map) => {
        acc[map._id] = map;
        return acc;
      }, {});
      setMaps(mapsDict);
    } catch (error) {
      setNotificationMessage('Error fetching maps.');
      setShowNotification(true);
    }
  };

  useEffect(() => {
    if (showRewards) {
      fetchMapData();
      fetchUserRewards();
    }
    // eslint-disable-next-line
  }, [showRewards]);

  const fetchUserRewards = async () => {
    try {
      const data = await getRewards(userAccount);
      setRewardsWithLogs(Array.isArray(data.rewards) ? data.rewards : []);
    } catch (error) {
      setNotificationMessage('Error fetching rewards.');
      setShowNotification(true);
      setRewardsWithLogs([]);
    }
  };

  const handleCollectRewards = async () => {
    if (loading) return;
    setLoading(true);

    try {
      if (rewardsWithLogs.length === 0) {
        setNotificationMessage('No rewards available for collection.');
        setShowNotification(true);
        setLoading(false);
        return;
      }

      const data = await collectRewards(userAccount);

      if (data.details) {
        const { cpu_ok, net_ok } = data.details;
        if (!cpu_ok) {
          setNotificationMessage('CPU overloaded. Please try again later.');
          setShowNotification(true);
          setLoading(false);
          return;
        }
        if (!net_ok) {
          setNotificationMessage('NET overloaded. Please try again later.');
          setShowNotification(true);
          setLoading(false);
          return;
        }
      }

      const coinsCollected = data.totalCoins && data.totalCoins !== "0.00";
      const failedMints = data.mintErrors ? data.mintErrors.length : 0;

      if (coinsCollected && failedMints === 0) {
        setNotificationMessage('Rewards successfully collected!');
      } else if (coinsCollected || failedMints < rewardsWithLogs.length) {
        setNotificationMessage(
          'Some rewards were successfully collected, but others failed. Please try collecting the pending rewards again.'
        );
      } else {
        setNotificationMessage('Error collecting rewards. Please try again.');
      }

      if (fetchPXRS && typeof fetchPXRS === 'function') {
        await fetchPXRS();
      }

      setShowNotification(true);
      setShowRewards(false);
    } catch (error) {
      if (error.message) {
        setNotificationMessage(error.message);
      } else {
        setNotificationMessage('Error collecting rewards. Please try again.');
      }
      setShowNotification(true);
    } finally {
      setLoading(false);
      fetchUserRewards(); 
    }
  };

  const toggleExpand = (rewardId) => {
    setExpandedRewards((prev) => ({
      ...prev,
      [rewardId]: !prev[rewardId],
    }));
  };

  const parseEventLine = (line) => {
    const ptsPattern = /\(([-+]?\d+)\s*pts\)$/i;
    const match = line.match(ptsPattern);
    let ptsValue = null;
    let text = line;
    if (match) {
      ptsValue = parseInt(match[1], 10);
      text = line.replace(ptsPattern, '').trim();
    }
    return { text, pts: ptsValue };
  };

  const getPtsColor = (pts) => {
    if (pts > 0) return '#00ff00'; 
    if (pts < 0) return '#ff0000'; 
    return '#ffffff'; 
  };

  // Marcadores:
  // Pre-Race Setup: Até e INCLUINDO "Starting the race..."
  // Race Events: Linhas ENTRE (exclusivo) "Starting the race..." e "Score after race events:"
  // Post-Race Summary: Inclui "Score after race events:" e vai até "--- Final Standings ---"
  // Final Standings: Após "--- Final Standings ---"

  const renderEventsLog = (eventsLog) => {
    // Passo 1: Reorganizar eventsLog para mover "Experience gained: X XP" após "Coin chosen percent: Y%"
    const xpLogIndex = eventsLog.findIndex(line => line.includes('Experience gained:'));
    if (xpLogIndex !== -1) {
      const xpLog = eventsLog.splice(xpLogIndex, 1)[0];
      const coinPercentIndex = eventsLog.findIndex(line => line.includes('Coin chosen percent:'));
      if (coinPercentIndex !== -1) {
        eventsLog.splice(coinPercentIndex + 1, 0, xpLog);
      } else {
        // Se "Coin chosen percent:" não for encontrado, adiciona no final
        eventsLog.push(xpLog);
      }
    }

    const finalStandingsIndex = eventsLog.findIndex(line => line.includes('--- Final Standings ---'));
    const startingRaceIndex = eventsLog.findIndex(line => line.includes('Starting the race...'));
    const scoreAfterIndex = eventsLog.findIndex(line => line.includes('Score after race events:'));

    let preRace = [];
    let raceEvents = [];
    let postRace = [];
    let finalStandingsLines = [];

    // Final Standings
    if (finalStandingsIndex !== -1) {
      finalStandingsLines = eventsLog.slice(finalStandingsIndex + 1);
    }

    // Pre-Race Setup (inclui a linha "Starting the race...")
    if (startingRaceIndex !== -1) {
      preRace = eventsLog.slice(0, startingRaceIndex + 1);
    } else {
      // Se não existe startingRaceIndex, tudo é preRace até finalStandings
      preRace = (finalStandingsIndex !== -1) ? eventsLog.slice(0, finalStandingsIndex) : eventsLog;
    }

    // Race Events: entre startingRaceIndex e scoreAfterIndex, sem incluir eles
    if (startingRaceIndex !== -1 && scoreAfterIndex !== -1 && scoreAfterIndex > startingRaceIndex) {
      raceEvents = eventsLog.slice(startingRaceIndex + 1, scoreAfterIndex);
    }

    // Post-Race Summary: inclui "Score after race events:" se existir
    if (scoreAfterIndex !== -1) {
      // do scoreAfterIndex até finalStandingsIndex (ou até o fim)
      if (finalStandingsIndex !== -1) {
        postRace = eventsLog.slice(scoreAfterIndex, finalStandingsIndex);
      } else {
        postRace = eventsLog.slice(scoreAfterIndex);
      }
    } else {
      // Se não tiver scoreAfterIndex, não tem postRace separado
      if (finalStandingsIndex !== -1) {
        postRace = eventsLog.slice(preRace.length, finalStandingsIndex);
      }
    }

    // Standings Data
    const standingsData = [];
    finalStandingsLines.forEach(line => {
      const match = line.match(/^(\d+)º\s+(.*?)\:\s+(\-?\d+)\s+pts$/);
      if (match) {
        const position = parseInt(match[1], 10);
        const name = match[2].trim();
        const score = parseInt(match[3], 10);
        standingsData.push({ position, name, score });
      }
    });

    const renderSimpleList = (lines, title) => {
      if (!lines || lines.length === 0) return null;
      return (
        <div className="simple-list-section">
          <h4 className="log-section-title">{title}</h4>
          <ul className="simple-list">
            {lines.map((line, i) => (
              <li key={i} className="simple-list-item">{line}</li>
            ))}
          </ul>
        </div>
      );
    };

    const renderRaceEvents = (events) => {
      if (!events || events.length === 0) return null;
      // Apenas os eventos, sem cabeçalhos especiais
      // Cada evento pode ter pontos ou não, se não tiver, mostra "-"
      return (
        <div className="race-events-section">
          <h4 className="log-section-title">Race Events</h4>
          <table className="events-table">
            <thead>
              <tr>
                <th className="event-text-col">Event</th>
                <th className="event-pts-col">Points</th>
              </tr>
            </thead>
            <tbody>
              {events.map((line, idx) => {
                const { text, pts } = parseEventLine(line);
                const ptsColor = pts !== null ? getPtsColor(pts) : '#ffffff';
                const ptsDisplay = pts !== null ? ((pts > 0 ? '+' : '') + pts) : '-';
                return (
                  <tr key={idx} className="event-row">
                    <td className="event-text-col">{text}</td>
                    <td className="event-pts-col" style={{color: pts !== null ? ptsColor : '#ffffff'}}>
                      {ptsDisplay}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    };

    const renderFinalStandings = (data) => {
      if (data.length === 0) return null;
      return (
        <div className="final-standings-section">
          <h4 className="log-section-title">Final Standings</h4>
          <table className="standings-table">
            <thead>
              <tr>
                <th>Pos</th>
                <th>Name</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {data.map((player, i) => (
                <tr key={i}>
                  <td>{player.position}</td>
                  <td>{player.name}</td>
                  <td style={{color: player.score >= 0 ? '#00ff00' : '#ff0000'}}>{player.score} pts</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

    return (
      <div className="events-log-container">
        {renderSimpleList(preRace, "Pre-Race Setup")}
        {renderRaceEvents(raceEvents)}
        {renderSimpleList(postRace, "Post-Race Summary")}
        {renderFinalStandings(standingsData)}
      </div>
    );
  };

  return (
    <>
      <div className="rewards-overlay" onClick={() => setShowRewards(false)}></div>
      <div className={`rewards-modal ${showRewards ? 'show' : ''}`}>
        <button className="close-button" onClick={() => setShowRewards(false)}>
          ✖
        </button>
        <div className="rewards-header">
          <h2>REWARDS</h2>
          <button 
            className="collect-all-button" 
            onClick={handleCollectRewards} 
            disabled={loading}
          >
            {loading ? 'Collecting...' : 'Collect All'}
          </button>
        </div>
        <div className="rewards-list">
          {Array.isArray(rewardsWithLogs) && rewardsWithLogs.map((reward, index) => {
            const mapDetails = maps[reward.mapId] || {};
            const rewardCoins = reward.coins?.$numberDecimal
              ? parseFloat(reward.coins.$numberDecimal)
              : parseFloat(reward.coins || 0);

            return (
              <div key={index} className={`reward-item ${reward.seasonName ? 'ranking-reward' : ''}`}>
                {reward.seasonName ? (
                  <>
                    <div className="season-trophy-container">
                      <img src={seasontrophie} alt="Season Trophy" className="season-trophy-image" />
                      <div className="reward-ranking-season">{reward.seasonName}</div>
                    </div>
                    <div className="reward-details">
                      <div className="reward-ranking">Ranking: {reward.position}</div>
                      <div className="reward-coins-rewardmodal">PXRS: {rewardCoins.toFixed(2)}</div>
                      <div className="reward-ranking-nfts">
                        <div className="reward-ranking-nft-grid">
                          {[...Array(Math.max((reward.nfts || []).length, 6))].map((_, i) =>
                            reward.nfts && reward.nfts[i] ? (
                              <div key={i} className="reward-ranking-nft-slot">
                                <img
                                  src={`https://atomichub-ipfs.com/ipfs/${reward.nfts[i].img}`}
                                  alt={reward.nfts[i].name}
                                  className="reward-ranking-nft-image"
                                />
                                <div className="reward-ranking-nft-amount">x{reward.nfts[i].amount}</div>
                              </div>
                            ) : (
                              <div key={i} className="reward-ranking-nft-slot empty-ranking-nft-slot"></div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="reward-columns">
                    <div className="reward-column reward-map-column">
                      <img
                        src={mapDetails.image || 'path/to/default/map-image.png'}
                        alt="Map"
                        className="reward-map-image"
                      />
                      <div className="rewardmodal-map-name">{mapDetails.name || 'Unknown Map'}</div>
                    </div>

                    <div className="reward-column reward-details-column">
                      <img
                        src={`https://atomichub-ipfs.com/ipfs/${reward.carImage}`}
                        alt="Car"
                        className="reward-car-image"
                      />
                      <div className="reward-car-name">{reward.carName}</div>
                      <div className="reward-position">Position: {reward.position}</div>
                      <div className="reward-pxrs">PXRS: {rewardCoins.toFixed(2)}</div>
                    </div>

                    <div className="reward-column reward-nfts-column">
                      <div className="reward-nft-grid">
                        {[...Array(Math.max((reward.nfts || []).length, 6))].map((_, i) =>
                          reward.nfts && reward.nfts[i] ? (
                            <div key={i} className="reward-nft-slot">
                              <img
                                src={`https://atomichub-ipfs.com/ipfs/${reward.nfts[i].img}`}
                                alt={reward.nfts[i].name}
                                className="reward-nft-image"
                              />
                              <div className="reward-nft-amount">x{reward.nfts[i].amount}</div>
                            </div>
                          ) : (
                            <div key={i} className="reward-nft-slot empty-nft-slot"></div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {reward.eventsLog && reward.eventsLog.length > 0 && (
                  <div className="reward-log-toggle">
                    <button onClick={() => toggleExpand(reward._id)} className="toggle-button">
                      {expandedRewards[reward._id] ? 'Logs ▲' : 'Logs ▼'}
                    </button>
                  </div>
                )}

                {reward.eventsLog && reward.eventsLog.length > 0 && expandedRewards[reward._id] && (
                  <div className="reward-events-log expanded">
                    {renderEventsLog(reward.eventsLog)}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RewardsModal;
