import React, { useEffect, useState } from 'react';
import '../styles/ongoingRaces.css';
import { fetchOngoingRaces, fetchMaps } from '../utils/api';
import { useAuth } from '../contexts/AuthContext';
import { formatTime } from '../utils/formatTime';

const OngoingRaces = ({ setShowOngoingRaces }) => {
  const [ongoingRaces, setOngoingRaces] = useState([]);
  const [maps, setMaps] = useState({});
  const { userAccount } = useAuth();

  const fetchData = async () => {
    if (userAccount) {
      try {
        const races = await fetchOngoingRaces(userAccount);
        setOngoingRaces(races);
      } catch (error) {
        console.error('Error fetching ongoing races:', error.message);
      }
    } else {
      console.error('User account is not defined');
    }
  };

  // Fetch map data from mapsadded collection
  const fetchMapData = async () => {
    try {
      const mapsData = await fetchMaps();
      const mapsDict = mapsData.reduce((acc, map) => {
        acc[map._id] = map;  // Store maps in a dictionary by their _id for easy lookup
        return acc;
      }, {});
      setMaps(mapsDict);
    } catch (error) {
      console.error('Error fetching maps:', error.message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchMapData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000); // Atualiza a cada 60 segundos
    return () => clearInterval(interval);
  }, [userAccount]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setOngoingRaces((prevRaces) =>
        prevRaces.map((race) => ({
          ...race,
          remainingTime: Math.max(0, race.duration - Math.floor((new Date() - new Date(race.startTime)) / 1000))
        }))
      );
    }, 1000); // Atualiza o tempo restante a cada segundo

    return () => clearInterval(timerInterval);
  }, []);

  return (
    <>
      <div className="ongoing-races-overlay" onClick={() => setShowOngoingRaces(false)}></div>
      <div className="ongoing-races-modal show">
        <button className="close-button-ongoing" onClick={() => setShowOngoingRaces(false)}>✖</button>
        <div className="ongoing-races-header">
          <h2>ONGOING RACES</h2>
        </div>
        <div className="ongoing-races-list">
          {ongoingRaces.length > 0 ? (
            ongoingRaces.map((race, index) => {
              const mapDetails = maps[race.mapId] || {};  // Safely fetch map details using the mapId

              return (
                <div key={index} className="ongoing-race-item">
                  <div className="ongoing-race-columns">
                    {/* Coluna 1: Imagem do Mapa e Nome */}
                    <div className="ongoing-race-column ongoing-race-map-column">
                      <img
                        src={mapDetails.image || 'path/to/default/image.png'}
                        alt="Map"
                        className="ongoing-race-map-image"
                      />
                      <div className="ongoing-race-map-name">{mapDetails.name || 'Unknown Map'}</div>
                    </div>

                    {/* Coluna 2: Detalhes da Corrida */}
                    <div className="ongoing-race-column ongoing-race-details-column">
                      <img
                        src={race.carImageUrl || 'path/to/default/car-image.png'}
                        alt="Car"
                        className="ongoing-race-car-image"
                      />
                      <div className="ongoing-race-car-name">Car: {race.carName} (ID: {race.carId})</div>
                      <div className="ongoing-race-time">Time remaining: {formatTime(race.remainingTime)}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-ongoing-races">No ongoing races</div>
          )}
        </div>
      </div>
    </>
  );
};

export default OngoingRaces;
