import React, { useEffect, useState, useRef } from 'react';
import '../styles/raceAnimation.css';

const RaceStartAnimation = ({ onAnimationEnd }) => {
  const [showShake, setShowShake] = useState(false);

  // Comentado: Referências para os elementos de áudio
  // const countdownRef = useRef(new Audio(require('../assets/countdown.mp3')));
  // const goRef = useRef(new Audio(require('../assets/go.mp3')));
  // const flagRef = useRef(new Audio(require('../assets/flag.mp3')));

  useEffect(() => {
    // Comentado: Tocar som de contagem regressiva
    // countdownRef.current.play();

    // Comentado: Tocar som de "Go!" após 3 segundos
    // const countdownTimer = setTimeout(() => {
    //   goRef.current.play();
    // }, 3000);

    // Comentado: Tocar som da bandeira após 4 segundos
    // const flagTimer = setTimeout(() => {
    //   flagRef.current.play();
    // }, 4000);

    const animationTimer = setTimeout(() => {
      setShowShake(true);
      const shakeTimer = setTimeout(() => {
        setShowShake(false);
        onAnimationEnd();
      }, 2000);
      return () => clearTimeout(shakeTimer);
    }, 5000); // Duração total da animação (5s)

    return () => {
      // Comentado: Limpar timeouts dos sons
      // clearTimeout(countdownTimer);
      // clearTimeout(flagTimer);
      clearTimeout(animationTimer);
    };
  }, [onAnimationEnd]);

  return (
    <div className={`race-animation-container ${showShake ? 'screen-shake' : ''}`}>
      <div className="race-flag"></div>
      <div className="countdown countdown-3">3</div>
      <div className="countdown countdown-2">2</div>
      <div className="countdown countdown-1">1</div>
      <div className="countdown countdown-go">Go!</div>
    </div>
  );
};

export default RaceStartAnimation;
