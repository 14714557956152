import React, { useState, useEffect } from 'react';
import GlobalSettings from './GlobalSettings';
import MapManagement from './MapManagement';
import SeasonManagement from './SeasonManagement';
import StakeConfigSettings from './StakeConfigSettings';
import NftVerificationSettings from './NftVerificationSettings';
import ItemManagement from './ItemManagement';

const AdminPanel = ({ setShowAdminPanel, maps, setMaps }) => {
  const [showGlobalSettings, setShowGlobalSettings] = useState(false);
  const [showMapManagement, setShowMapManagement] = useState(false);
  const [showSeasonManagement, setShowSeasonManagement] = useState(false);
  const [showStakeConfig, setShowStakeConfig] = useState(false);
  const [showNftVerification, setShowNftVerification] = useState(false);
  const [showItemManagement, setShowItemManagement] = useState(false);

  return (
    <div className="admin-panel">
      <div className="admin-panel-header">
        <h2>Admin Panel</h2>
        <button className="close-admin-button" onClick={() => setShowAdminPanel(false)}>✘</button>
      </div>

      <div className="section-buttons">
        <button onClick={() => setShowGlobalSettings(!showGlobalSettings)}>Toggle Global Settings</button>
        <button onClick={() => setShowMapManagement(!showMapManagement)}>Toggle Map Management</button>
        <button onClick={() => setShowSeasonManagement(!showSeasonManagement)}>Toggle Season Management</button>
        <button onClick={() => setShowStakeConfig(!showStakeConfig)}>Toggle Stake Config</button>
        <button onClick={() => setShowNftVerification(!showNftVerification)}>Toggle NFT Verification</button>
        <button onClick={() => setShowItemManagement(!showItemManagement)}>Toggle Item Management</button>
      </div>

      {showGlobalSettings && <GlobalSettings />}
      {showMapManagement && <MapManagement maps={maps} setMaps={setMaps} />}
      {showSeasonManagement && <SeasonManagement />}
      {showStakeConfig && <StakeConfigSettings />}
      {showNftVerification && <NftVerificationSettings />}
      {showItemManagement && <ItemManagement />}
    </div>
  );
};

export default AdminPanel;
