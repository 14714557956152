const ENV = process.env.REACT_APP_WAX_ENV || 'testnet'; // Define o ambiente ('mainnet' ou 'testnet')

const ENDPOINTS = {
  mainnet: {
    WAX_API: {
      FETCH_NFTS: 'https://wax.api.atomicassets.io/atomicassets/v1/assets',
      FETCH_NFT_DETAILS: 'https://wax.api.atomicassets.io/atomicassets/v1/assets',
      FETCH_NFTS_ADMINPANEL: 'https://wax.api.atomicassets.io/atomicassets/v1/templates',
      PXRS_BALANCE: 'https://wax.greymass.com/v1/chain/get_currency_balance',
    },
    COLLECTION_NAME: 'pixelracesgo',
    PXRS_CONTRACT: 'pixelracesmg',
    CHAIN: {
      id: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4', // Chain ID da Mainnet
      url: 'https://wax.greymass.com', // URL da Mainnet
    },
    CLOUD_WALLET: {
      url: 'https://www.mycloudwallet.com',
      autoUrl: 'https://idm-api.mycloudwallet.com/v1/accounts/auto-accept',
      loginTimeout: 300000,
    },
  },
  testnet: {
    WAX_API: {
      FETCH_NFTS: 'https://test.wax.api.atomicassets.io/atomicassets/v1/assets',
      FETCH_NFTS_ADMINPANEL: 'https://test.wax.api.atomicassets.io/atomicassets/v1/templates',
      FETCH_NFT_DETAILS: 'https://test.wax.api.atomicassets.io/atomicassets/v1/assets',
      PXRS_BALANCE: 'https://testnet.wax.pink.gg/v1/chain/get_currency_balance',
    },
    COLLECTION_NAME: 'waxracingwax',
    PXRS_CONTRACT: 'racingwaxca2',
    CHAIN: {
      id: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12', // Chain ID da Testnet
      url: 'https://waxtest.api.eosnation.io', // URL da Testnet
    },
    CLOUD_WALLET: {
      url: 'https://www.mycloudwallet.com',
      autoUrl: 'https://idm-api.mycloudwallet.com/v1/accounts/auto-accept',
      loginTimeout: 300000,
    },
  },
};

export default ENDPOINTS[ENV];
