// nftselector.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { fetchNFTs, checkStake, checkCarInRace } from '../utils/api';
import '../styles/nftSelector.css';
import { TailSpin } from 'react-loader-spinner';

const NFTSelector = ({
    selectedCars,
    onSelectNFT, // Alterado de setSelectedCars para onSelectNFT
    setShowSelectCar,
    setNotificationMessage,
    setShowNotification
}) => {
    const { userAccount } = useAuth();
    const [nfts, setNfts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [isLoading, setIsLoading] = useState(false);
    const [stakedNfts, setStakedNfts] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const [isCooldown, setIsCooldown] = useState(false);
    const [cooldownTime, setCooldownTime] = useState(0);

    const [selectedRarities, setSelectedRarities] = useState([]);
    const [showStaked, setShowStaked] = useState(true);
    const [showRacing, setShowRacing] = useState(true);

    const [tempSelected, setTempSelected] = useState([]);

    const MAX_REFRESH_CLICKS = 5;
    const COOLDOWN_DURATION = 10;
    const MAX_CARS = 10;

    // Calculate how many cars have already been selected
    const alreadySelected = selectedCars.length;
    // Calculate how many cars can still be selected
    const maxSelectable = MAX_CARS - alreadySelected;

    useEffect(() => {
        const savedSelectedRarities = JSON.parse(localStorage.getItem('selectedRarities_nftSelector')) || [];
        const savedShowStaked = JSON.parse(localStorage.getItem('showStaked_nftSelector'));
        const savedShowRacing = JSON.parse(localStorage.getItem('showRacing_nftSelector'));

        setSelectedRarities(savedSelectedRarities);
        if (savedShowStaked !== null) setShowStaked(savedShowStaked);
        if (savedShowRacing !== null) setShowRacing(savedShowRacing);
    }, []);

    useEffect(() => {
        localStorage.setItem('selectedRarities_nftSelector', JSON.stringify(selectedRarities));
    }, [selectedRarities]);

    useEffect(() => {
        localStorage.setItem('showStaked_nftSelector', JSON.stringify(showStaked));
    }, [showStaked]);

    useEffect(() => {
        localStorage.setItem('showRacing_nftSelector', JSON.stringify(showRacing));
    }, [showRacing]);

    useEffect(() => {
        if (userAccount) {
            loadNfts();
            checkRefreshClicks();
        }
    }, [userAccount]);

    useEffect(() => {
        let cooldownInterval;

        if (isCooldown && cooldownTime > 0) {
            cooldownInterval = setInterval(() => {
                setCooldownTime((prevTime) => prevTime - 1);
            }, 1000);
        } else if (cooldownTime <= 0 && isCooldown) {
            clearInterval(cooldownInterval);
            setIsCooldown(false);
            setCooldownTime(0);
            setRefreshCount(0);
            localStorage.removeItem('refreshClicks_nftSelector');
        }

        return () => clearInterval(cooldownInterval);
    }, [isCooldown, cooldownTime]);

    const checkRefreshClicks = () => {
        const refreshClicks = JSON.parse(localStorage.getItem('refreshClicks_nftSelector')) || [];
        const now = Date.now();
        const validClicks = refreshClicks.filter((clickTime) => now - clickTime < 60000);
        localStorage.setItem('refreshClicks_nftSelector', JSON.stringify(validClicks));
        setRefreshCount(validClicks.length);
    };

    const loadNfts = async () => {
        setIsLoading(true);
        try {
            const staked = await checkStake();
            setStakedNfts(staked);

            const userNfts = await fetchNFTs(userAccount);
            const availableNfts = await Promise.all(
                userNfts.map(async (nft) => {
                    const isInRace = await checkCarInRace(nft.asset_id);
                    return {
                        ...nft,
                        inRace: isInRace.inRace,
                        inStake: staked.includes(nft.asset_id),
                    };
                })
            );
            setNfts(availableNfts);
        } catch (error) {
            console.error('Error loading NFTs:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRefresh = () => {
        if (isCooldown) {
            return;
        }

        const now = Date.now();
        const refreshClicks = JSON.parse(localStorage.getItem('refreshClicks_nftSelector')) || [];

        if (refreshCount >= MAX_REFRESH_CLICKS) {
            setIsCooldown(true);
            setCooldownTime(COOLDOWN_DURATION);
            localStorage.setItem('refreshClicks_nftSelector', JSON.stringify(refreshClicks));
        } else {
            loadNfts();
            const updatedClicks = [...refreshClicks, now];
            localStorage.setItem('refreshClicks_nftSelector', JSON.stringify(updatedClicks));
            setRefreshCount(refreshCount + 1);
        }
    };

    const getUniqueRarities = () => {
        const rarities = nfts
            .map((nft) => nft.template?.immutable_data['Car Rarity'])
            .filter(Boolean);
        return [...new Set(rarities)];
    };

    const filteredNfts = nfts.filter((nft) => {
        const matchesRarity =
            selectedRarities.length === 0 ||
            selectedRarities.includes(nft.template?.immutable_data['Car Rarity']);
        const matchesStaking = showStaked || !nft.inStake;
        const matchesRacing = showRacing || !nft.inRace;
        return matchesRarity && matchesStaking && matchesRacing;
    });

    const totalPages = Math.ceil(filteredNfts.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedNfts = filteredNfts.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Function to check if the NFT is already selected
    const isAlreadySelected = (nft) => selectedCars.some(car => car.asset_id === nft.asset_id);

    const toggleSelectCar = (nft) => {
        if (isAlreadySelected(nft)) {
            setNotificationMessage('This car is already selected.');
            setShowNotification(true);
            return;
        }

        const isSelected = tempSelected.some(car => car.asset_id === nft.asset_id);
        if (isSelected) {
            setTempSelected(tempSelected.filter(car => car.asset_id !== nft.asset_id));
        } else {
            if (tempSelected.length < maxSelectable) {
                setTempSelected([...tempSelected, nft]);
            } else {
                setNotificationMessage(`You can only select up to ${maxSelectable} more car(s).`);
                setShowNotification(true);
            }
        }
    };

    const confirmSelection = () => {
        // Chama a função onSelectNFT com o array de NFTs selecionados
        onSelectNFT(tempSelected);
        setShowSelectCar(false);
    };

    return (
        <>
            <div
                className="nft-selector-overlay"
                onClick={() => setShowSelectCar(false)}
            ></div>
            <div className="nft-selector-modal">
                <div className="nft-selector-header">
                    <h2>Select Your NFT</h2>
                    <button
                        className="nft-selector-close-button"
                        onClick={() => setShowSelectCar(false)}
                    >
                        X
                    </button>
                </div>

                {/* Filters */}
                <div className="nft-selector-filters">
                    <div className="filter-section-nftselector">
                        <h4>Filter by Rarity:</h4>
                        <div className="rarity-options">
                            {getUniqueRarities().map((rarity) => (
                                <label key={rarity} className="rarity-label-nftselector">
                                    <input
                                        type="checkbox"
                                        value={rarity}
                                        checked={selectedRarities.includes(rarity)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedRarities([...selectedRarities, rarity]);
                                            } else {
                                                setSelectedRarities(selectedRarities.filter((r) => r !== rarity));
                                            }
                                            setCurrentPage(1);
                                        }}
                                    />
                                    {rarity}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="filter-section-nftselector">
                        <h4>Show Staked Cars:</h4>
                        <label className="toggle-label">
                            <input
                                type="checkbox"
                                checked={showStaked}
                                onChange={(e) => {
                                    setShowStaked(e.target.checked);
                                    setCurrentPage(1);
                                }}
                            />
                            {showStaked ? 'Yes' : 'No'}
                        </label>
                    </div>
                    <div className="filter-section-nftselector">
                        <h4>Show Racing Cars:</h4>
                        <label className="toggle-label">
                            <input
                                type="checkbox"
                                checked={showRacing}
                                onChange={(e) => {
                                    setShowRacing(e.target.checked);
                                    setCurrentPage(1);
                                }}
                            />
                            {showRacing ? 'Yes' : 'No'}
                        </label>
                    </div>
                </div>

                <button
                    className="nft-selector-refresh-button"
                    onClick={handleRefresh}
                    disabled={isCooldown}
                >
                    {isCooldown
                        ? `Wait... ${cooldownTime}s`
                        : 'Refresh'}
                </button>

                {tempSelected.length > 0 && (
                    <button
                        className="select-cars-button"
                        onClick={confirmSelection}
                    >
                        Selected {tempSelected.length} car{tempSelected.length > 1 ? 's' : ''}
                    </button>
                )}

                <div className="nft-selector-grid-container">
                    {isLoading ? (
                        <div className="nft-selector-loading-container">
                            <div className="nft-selector-spinner-wrapper">
                                <TailSpin
                                    height="100"
                                    width="100"
                                    color="#00ff00"
                                    ariaLabel="loading"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="nft-selector-grid">
                            {paginatedNfts.length > 0 ? (
                                paginatedNfts.map((nft) => {
                                    const isSelected = tempSelected.some(car => car.asset_id === nft.asset_id);
                                    const alreadySelectedFlag = isAlreadySelected(nft);
                                    return (
                                        <div
                                            key={nft.asset_id}
                                            className={`nft-selector-item ${isSelected ? 'nft-selector-selected' : ''} ${nft.inRace || nft.inStake ? 'inactive' : ''} ${alreadySelectedFlag ? 'already-selected' : ''}`}
                                            onClick={() => {
                                                if (alreadySelectedFlag) {
                                                    setNotificationMessage('This car is already selected.');
                                                    setShowNotification(true);
                                                    return;
                                                }
                                                if (!nft.inRace && !nft.inStake) {
                                                    toggleSelectCar(nft);
                                                } else {
                                                    setNotificationMessage('This car cannot be selected.');
                                                    setShowNotification(true);
                                                }
                                            }}
                                        >
                                            {nft.template && nft.template.immutable_data ? (
                                                <>
                                                    <div className="nft-selector-image-container">
                                                        <img
                                                            src={`https://atomichub-ipfs.com/ipfs/${nft.template.immutable_data.img2}`}
                                                            alt={
                                                                nft.template
                                                                    .immutable_data
                                                                    .name
                                                            }
                                                            className="nft-selector-image"
                                                        />
                                                    </div>
                                                    <div className="nft-selector-name">
                                                        {nft.template.immutable_data.name}
                                                    </div>
                                                    <div className="nft-selector-id">
                                                        {nft.asset_id}
                                                    </div>
                                                    <div className="nft-selector-rarity">
                                                        {
                                                            nft.template
                                                                .immutable_data[
                                                                'Car Rarity'
                                                            ]
                                                        }
                                                    </div>
                                                    {(nft.inRace ||
                                                        nft.inStake) && (
                                                            <div
                                                                className={`status-overlay ${
                                                                    nft.inRace
                                                                        ? 'in-race'
                                                                        : 'in-stake'
                                                                }`}
                                                            >
                                                                {nft.inRace
                                                                    ? 'Racing'
                                                                    : 'Staked'}
                                                            </div>
                                                        )}
                                                </>
                                            ) : (
                                                <div className="nft-selector-error">
                                                    NFT data not available
                                                </div>
                                            )}
                                            {alreadySelectedFlag && (
                                                <div className="already-selected-overlay">
                                                    Already Selected
                                                </div>
                                            )}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-nfts-message">
                                    No NFTs match the selected filters.
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="nft-selector-pagination">
                    {[...Array(totalPages).keys()].map((number) => (
                        <button
                            key={number}
                            onClick={() => paginate(number + 1)}
                            className={`nft-selector-pagination-button ${currentPage === number + 1 ? 'nft-selector-pagination-button-active' : ''}`}
                        >
                            {number + 1}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default NFTSelector;
