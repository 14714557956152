import React, { useEffect, useState } from 'react';
import '../styles/mapsSection.css';
import { fetchMaps } from '../utils/api';
import io from 'socket.io-client';
import config from '../config';

const socket = io(config.websocketUrl, {
  transports: ['websocket', 'polling', 'flashsocket'],
});

const formatTime = (time) => {
  if (isNaN(time) || time < 0) return '0h 0m 0s';

  const days = Math.floor(time / (24 * 3600));
  time %= 24 * 3600;

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  const dayDisplay = days > 0 ? `${days}d ` : '';

  return `${dayDisplay}${hours}h ${minutes}m ${seconds}s`;
};

const MapDisplay = ({
  maps,
  setMaps,
  selectedMap,
  handleSelectMap,
  currentMapIndex,
  setCurrentMapIndex,
}) => {
  const [mapDurations, setMapDurations] = useState({});

  useEffect(() => {
    console.log('Attempting to connect to WebSocket...');
    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('connect_error', (err) => {
      console.error('WebSocket connection error:', err);
    });

    return () => {
      socket.off('connect');
      socket.off('connect_error');
    };
  }, []);

  const loadMaps = async () => {
    try {
      const mapsData = await fetchMaps();
      // Sort maps to display the most recently added first
      const sortedMaps = mapsData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setMaps(sortedMaps);

      const now = Date.now();
      const durations = sortedMaps.reduce((acc, map) => {
        const elapsed = Math.floor(
          (now - new Date(map.createdAt).getTime()) / 1000
        );
        acc[map._id] = Math.max(map.duration - elapsed, 0);
        return acc;
      }, {});
      setMapDurations(durations);
    } catch (error) {
      console.error('Error fetching maps:', error);
    }
  };

  useEffect(() => {
    loadMaps();
    const intervalId = setInterval(loadMaps, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    socket.on('map_timer', ({ id, duration }) => {
      console.log(`Received map_timer for map ${id} with duration ${duration}`);
      setMapDurations((prevDurations) => ({
        ...prevDurations,
        [id]: duration,
      }));
    });

    return () => {
      socket.off('map_timer');
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMapDurations((prevDurations) => {
        const newDurations = { ...prevDurations };
        for (const mapId in newDurations) {
          if (newDurations[mapId] > 0) {
            newDurations[mapId] -= 1;
          }
        }
        return newDurations;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const mapsPerPage = 3; // Número de mapas por página

  // Filtrar e ordenar os mapas ativos novamente para garantir que o mais novo fique em primeiro
  const activeMaps = maps
    .filter((map) => map.status !== 'expired' && mapDurations[map._id] > 0)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const totalPages = Math.ceil(activeMaps.length / mapsPerPage);
  const currentPage = Math.floor(currentMapIndex / mapsPerPage) + 1;

  const displayMaps = activeMaps.slice(
    currentMapIndex,
    currentMapIndex + mapsPerPage
  );

  const handlePrevClick = () => {
    setCurrentMapIndex((prevIndex) => {
      const newIndex = prevIndex - mapsPerPage;
      if (newIndex < 0) {
        const remainder = activeMaps.length % mapsPerPage;
        const lastPageIndex =
          remainder === 0
            ? activeMaps.length - mapsPerPage
            : activeMaps.length - remainder;
        return lastPageIndex;
      }
      return newIndex;
    });
  };

  const handleNextClick = () => {
    setCurrentMapIndex((prevIndex) => {
      const newIndex = prevIndex + mapsPerPage;
      if (newIndex >= activeMaps.length) {
        return 0;
      }
      return newIndex;
    });
  };

  return (
    <div className="maps-section-container">
      {activeMaps.length > 0 && (
        <div className="page-indicator">
          Page {currentPage} of {totalPages}
        </div>
      )}
      <div className="maps-section">
        {activeMaps.length > 0 ? (
          <div className="map-carousel">
            {displayMaps.map((map, index) => (
              <div
                key={index}
                className={`map ${
                  selectedMap && selectedMap._id === map._id ? 'selected-map' : ''
                }`}
                onClick={() => handleSelectMap(map)}
              >
                <img src={map.image} alt={map.name} className="map-image" />
                <div className="map-name">{map.name}</div>
                <div className="map-timer">
                  {formatTime(mapDurations[map._id])}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-maps-placeholder">No active maps</div>
        )}
      </div>
      {activeMaps.length > mapsPerPage && (
        <>
          <button className="nav-button prev" onClick={handlePrevClick}>
            ❮
          </button>
          <button className="nav-button next" onClick={handleNextClick}>
            ❯
          </button>
        </>
      )}
    </div>
  );
};

export default MapDisplay;
