// client/src/components/Notification.js

import React, { useEffect } from 'react';
import '../styles/notification.css';

const Notification = ({ message, setShowNotification }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNotification(false);
    }, 3000); // Notificação some após 3 segundos

    return () => clearTimeout(timer);
  }, [setShowNotification]);

  return (
    <div className="notification">
      {message}
    </div>
  );
};

export default Notification;
